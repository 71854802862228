import { React, useEffect, useState } from 'commons';
import ExportListItem from 'shared/views/export/ListItem';
import ExportApi from 'shared/api/Export';

export default function ExportSpecificList(props) {

    const [elements, setElements] = useState([]);
    const { type, title } = props;

    useEffect(() => {
        ExportApi.list(type).then(data => setElements(data.exports));
        // eslint-disable-next-line
    } , []);

    return (
        <>
            {elements.length > 0 &&
                <section>
                    <h3>{title}</h3>
                    <table className="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th>Etat</th>
                                <th>Création</th>
                                <th>Mise à jour</th>
                                <th>Fichier</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {elements.map((element, index) => {
                                return <ExportListItem data={element} index={index} />
                            })}
                        </tbody>
                    </table>
                </section>
            }
        </>
    );
}