import { React, useState, useEffect } from "commons";

export default function DomainSelectionItemComponent(props) {

    const [active, setActive] = useState(false);
    const hasChildren = props.item.children && props.item.children.length > 0;
    const className = [hasChildren ? "haschildren" : "nochildren"];
    if (!props.selection.includes(parseInt(props.item.value)) && checkIfChildrenSelected(props.item)) {
        className.push("childrenselected")
    }

    function checkIfChildrenSelected(item) {
        let result = false;
        if (item.children && item.children.length > 0) {
            item.children.forEach(child => {
                result = result || props.selection.includes(parseInt(child.value));
                if (!result
                    && child.children
                    && child.children.length > 0
                ) {
                    result = result || checkIfChildrenSelected(child);
                }
            });
        }
        return result;
    }

    function toggleTree() {
        setActive(props.treeUnfold);
    }

    useEffect(toggleTree, [props.treeUnfold]);

    return <li className={className.join(" ")}>
            {hasChildren && <>
                <button type="button" className="btn btn-primary icon icon-only" onClick={() => active === true ? setActive(false) : setActive(true)}>
                    <i className={active ? "icon-boutons-moins" : "icon-boutons-plus"}></i>
                </button>
            </>}

            <input 
                onChange={e => props.onChange(props.item, e.target.checked)}
                type="checkbox"
                name={`${props.fieldname}-${props.item.value}`}
                id={`${props.fieldname}-${props.item.value}`}
                value={props.item.value}
                checked={props.selection.includes(parseInt(props.item.value))}
            />
            <label htmlFor={`${props.fieldname}-${props.item.value}`}>{props.item.label}</label>

            {active && hasChildren && <ul>
                {props.item.children.map(child => <DomainSelectionItemComponent 
                    item={child}
                    fieldname={props.fieldname}
                    onChange={props.onChange}
                    selection={props.selection}
                    treeUnfold={props.treeUnfold}
                />)}
            </ul>}
        </li>
}