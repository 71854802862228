import ApiRequest from 'shared/services/ApiRequest';
import AbstractApi from 'shared/api/Abstract';
import FileApi from 'shared/api/File';

const AccountApi = {

    search: function(query, from, size, sorting, direction) {
        return AbstractApi.search("accounts/searches", query, from, size, sorting, direction);
    },
    
    list: function(limit, sort = "name", direction = "ASC") {
        let params = {
            limit: limit,
            sort: sort
        };
        if (direction === "DESC") {
            params.desc = sort;
        }
        return AbstractApi.list("accounts", params);
    },

    getTree: function(id) {
        return ApiRequest.get("accounts/" + id + "/tree").then(response => response.data);
    },

    getUserTree: function(read = false) {
        return ApiRequest
            .get("accounts/usertree" + (read ? "?read=1" : ""))
            .then(response => response.data);
    },

    getConsultantTree: function(id) {
        return ApiRequest.get("accounts/" + id + "/consultanttree").then(response => response.data);
    },

    getConsultants: function(id) {
        return ApiRequest
            .get("accounts/" + id + "/consultants")
            .then(response => [
                response.data.results, 
                response.data.count,
                response.data.total
            ]);
    },

    getPilotStartConsultant: function() {
        return ApiRequest
            .get("accounts/pilotstartconsultant")
            .then(response => response.data);
    },
    
    get: function(id) {
        return AbstractApi.get("accounts", id);
    },
    
    export: function(query) {
        return AbstractApi.export("accounts", query);
    },
    
    save: function(data) {
        return AbstractApi.save("accounts", data);
    },

    saveConfiguration: function(data) {
        return AbstractApi.save("accounts/configurations", data);
    },
    
    uploadLogo: function(files) {
        return FileApi.upload("accounts/upload/logo", files);
    },

    useraccounts: function (email) {
        return ApiRequest
            .get("accounts/user/" + email)
            .then(response => [
                response.data.results, 
                response.data.count,
                response.data.total
            ]);
    }
}
export default AccountApi;
