import FileApi from 'shared/api/File'
import fileDownload from 'js-file-download';
import { toast } from 'react-toastify';
import Slugify from 'slugify';

const FileUtils = {

    download: function(file) {
        return FileApi
            .download(file.hash)
            .then(binaries => {
                return fileDownload(binaries, file.name);
            })
            .catch(error => toast.error("Erreur lors du téléchargement du fichier " + file.name +"."));
    },

    getFileNameDate: function() {
        return (new Date()).toISOString().split('T')[0].replace(/-/g, '');
    },

    getFilePrefix: function() {
        return FileUtils.getFileNameDate() + '-Apave-PilotVeille';
    },

    getCleanFileName: function(fileName) {
        return Slugify(fileName.toLowerCase(), {remove: /[*+~.()'"#!:@]/g});
    },
}
export default FileUtils;