import { React } from 'react';
import { formatDate } from 'shared/services/Utils';
import fileDownload from 'js-file-download';
import ExportApi from 'shared/api/Export';

export default function ExportListItem(props) {
    const { data, index } = props;
    const className = index % 2 === 0 ? "" : "bg-gris-25";
    const exportStatusLabels = {
        0: "En attente de traitement",
        1: "En cours de génération",
        2: "Disponible au téléchargement",
        3: "Téléchargé",
        4: "Supprimé",
        10: "Erreur lors de la génération",
    };

    function downloadFile(e, id, filename) {
        ExportApi.download(id)
            .then(data => {
                let readyFiles = localStorage.getItem("exportReady") ? localStorage.getItem("exportReady") : 1;
                localStorage.setItem("exportReady", (readyFiles - 1));
                fileDownload(data, filename);
            });
    }

    return (
        <>
            <tr id={`row-${index}`} className={className}>
                <td>{exportStatusLabels[data.status]}</td>
                <td>{formatDate(data.created_at, true)}</td>
                <td>{formatDate(data.updated_at, true)}</td>
                <td>{data.filename}</td>
                <td>{data.status === 2 &&
                    <button className="btn" onClick={e => {downloadFile(e, data.id, data.filename); e.target.parentNode.parentNode.remove()}}>
                        Télécharger
                    </button>
                }</td>
            </tr>
        </>
    );
}