export const SHEET_ACTION_SAVE = 0;
export const SHEET_ACTION_RETAIN = 1;
export const SHEET_ACTION_REACTIVATE = 2;
export const SHEET_ACTION_ARCHIVE = 3;
export const SHEET_ACTION_VALIDATE_SYNTHESIS = 4;
export const SHEET_ACTION_VALIDATE_TECHNICAL = 5;
export const SHEET_ACTION_TECHNICAL_UNNECESSARY = 6;
export const SHEET_ACTION_ADD_TO_MY_PUSH = 7;
export const SHEET_ACTION_ADD_TO_ACCOUNT_PUSH = 8;
export const ACTION_TODO_SYNTHESIS = 9;
export const ACTION_TODO_TECHNICAL = 10;
export const ACTION_TODECIDE_TECHNICAL = 11;
export const SHEET_ACTION_PRIVATE_AFFECT_WATCHSITE = 12;

export const SHEET_ACTION = {
    "save-sheet": SHEET_ACTION_SAVE,
    "save-and-retain-sheet": SHEET_ACTION_RETAIN,
    "save-and-reactivate-sheet": SHEET_ACTION_REACTIVATE,
    "save-and-archive-sheet": SHEET_ACTION_ARCHIVE,
    "save-and-validate-synthesis-sheet": SHEET_ACTION_VALIDATE_SYNTHESIS,
    "save-and-validate-technical-sheet": SHEET_ACTION_VALIDATE_TECHNICAL,
    "save-and-technical-unnecessary-sheet": SHEET_ACTION_TECHNICAL_UNNECESSARY,
    "save-and-add-to-my-push": SHEET_ACTION_ADD_TO_MY_PUSH,
    "save-and-add-to-account-push": SHEET_ACTION_ADD_TO_ACCOUNT_PUSH,
    "save-and-todo-synthesis": ACTION_TODO_SYNTHESIS,
    "save-and-todo-technical": ACTION_TODO_TECHNICAL,
    "save-and-todecide-technical": ACTION_TODECIDE_TECHNICAL,
};

export function getSheetActionName(action) {
    return Object.keys(SHEET_ACTION).find(key => SHEET_ACTION[key] === action);
}