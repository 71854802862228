import _ from "lodash";

export default function ListReadOnlyComponent(props) {
    
    const { value, options, keyField, valueField, separator } = props;
    let values = value;

    if (!_.isArray(value)) {
        values = [value];
    }

    let finalValues = _.compact(
        _.map(options, (o) => {
            if (values.includes(o[keyField])) {
                return o[valueField];
            }
            return null;
        })
    );

    return finalValues.join(separator);
}

ListReadOnlyComponent.defaultProps = {
    options: [],
    value: [],
    valueField: 'label',
    keyField: 'value',
    separator: ', ',
};
