import ApiRequest from 'shared/services/ApiRequest';
import AbstractApi from 'shared/api/Abstract';

const UserApi = {

    exist: function(email) {
        return ApiRequest.get("users/exist/" + email).then(response => response.data);
    },

    isExternal: function(email) {
        return ApiRequest.get("users/" + email + "/external").then(response => response.data.is_external);
    },

    delete: function(id) {
        return AbstractApi.delete("users", id);
    },

    bulkUpdate: function(ids, data) {
        return ApiRequest.patch(
            "users/bulk/update",
            {
                "ids": ids,
                "data": data
            }
        );
    },

    affectDomainAlert(ids, referential) {
        return ApiRequest.post(
            "users/affectdomainalert",
            {
                "ids": ids,
                "referential": referential
            }
        );
    }
}
export default UserApi;
