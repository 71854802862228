import ApiRequest from 'shared/services/ApiRequest';
import AbstractApi from 'shared/api/Abstract';

const ConfigurationApi = {

    get: function(code) {
        return AbstractApi.get("configurations", code);
    },

    update: function(code, data) {
        return ApiRequest.put("configurations/" + code, data).then(response => response.data);
    },

    bulkUpdate: function(configurations) {
        return ApiRequest.patch("configurations/bulk/update", {"configurations": configurations});
    },

    list: function() {
        return AbstractApi.list("configurations");
    }
}
export default ConfigurationApi;
