import React from 'react';

export default function LimitComponent(props) {

    function limitChange(event) {
        props.onChange(event.target.value);
    }

    return (
        <div className="nb-results">
            <div>
                <label className="sr-only">Résultats par page&nbsp;:</label>
                <select value={props.value} onChange={limitChange}>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </select>
            </div>
        </div>
    );
}
