export const SHEET_TODO = {
    0: "Aucune action nécessaire",
    1: "Consolidation du texte",
    2: "Création d'une ou de plusieurs parties de codes",
    3: "Consolidation d'exigences",
    4: "Consolidation de synthèses",
    5: "Mises à jour de projets de texte",
    6: "Création ou mise à jour de date à retenir",
    7: "Texte à abroger",
    8: "Abrogation d'un texte en différé",
    9: "Entrée en vigueur d'un texte en différé",
    10: "Entrée en vigueur d'un texte à plusieurs dates différées",
};

export function getSheetTodo() {
    let sheetAction = [];
    for (const [key, value] of Object.entries(SHEET_TODO)) {
        sheetAction.push({
            value: parseInt(key),
            label: value
        });
    }
    return sheetAction;
}

export function getSheetTodoLabel(todo) {
    return SHEET_TODO[todo];
}
