import ChecksingleReadOnlyComponent from "shared/components/ChecksingleReadOnlyComponent";

export default function ChecksingleListReadOnlyComponent(props) {

    const { value, options } = props;

    return (
        <fieldset>
            <div>
                <ul className="arborescence no-arrow">
                    {options.map(option =>
                        <li key={option.id}>
                            <ChecksingleReadOnlyComponent 
                                value={value.includes(option.id)} 
                            />
                            <label>{option.value}</label>
                        </li>
                    )}
                </ul>
            </div>
        </fieldset>
    );
}