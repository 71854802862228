import _ from 'lodash';
import ExternalUserApi from 'shared/api/ExternalUser';
import { compileDataToSelectOptions } from 'shared/services/Utils';

function ExternalUserAutocomplete(criterias, callback, orderingField = 'lastname', orderingDirection = 'ASC') {

    if (criterias.keyword === "" || criterias.keyword.length < 3) {
        callback([]);
    }

    let queryArgs = {
        "bool": {
            "must": [
                {
                    "bool": {
                        "should": [
                            {"wildcard": {"firstname": criterias.keyword + "*"}},
                            {"wildcard": {"lastname": criterias.keyword + "*"}},
                            {"wildcard": {"email": criterias.keyword + "*"}}
                        ]
                    }
                },
            ]
        }
    };

    if(criterias.account) {
        queryArgs["bool"]["must"].push(
            {"match": {"account_id": criterias.account}}
        );
    }

    ExternalUserApi
        .search(queryArgs, 0, 25, orderingField, orderingDirection)
        .then(([users]) => {
            callback(
                compileDataToSelectOptions(users, 'user_id', ['firstname', 'lastname'])
            );
        });
}

export default _.debounce(ExternalUserAutocomplete, 300);