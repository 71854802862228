import React from 'react';

export default function SidebarComponent(props) {

    function jump(event, target) {
        event.preventDefault();
        document.querySelector("#main-content").scrollTo(0, document.querySelector(target).offsetTop);
        return false;
    }

    return (
        <div className="sticky">
            <aside>
                <nav>
                    <h3>{props.label}</h3>
                    <ul>
                        {props.links.map(link => (<React.Fragment key={link.anchor}>
                            {link.active && (<>
                                <li><a href={link.anchor} onClick={(e) => jump(e, link.anchor)}>{link.label}</a></li>
                                {link.childs && (
                                    <ul>
                                        {link.childs.map(childLink => 
                                            <React.Fragment key={childLink.anchor}>
                                                {childLink.active && <li><a href={childLink.anchor}>{childLink.label}</a></li>}
                                            </React.Fragment>
                                        )}
                                    </ul>
                                )}
                            </>)}
                        </React.Fragment>))}
                    </ul>
                </nav>
            </aside>
        </div>
    )
}