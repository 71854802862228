import React, { createContext, useReducer } from 'react';

const reducer = (state, action) => {
    if (action.action === "populate") {
        const newState = {...state};
        newState[action.type] = action.data;
        return newState;
    }
    if (action.action === "status") {
        return {
            ...state,
            ...{status: action.data}
        };
    }
    throw new Error();
};

export const ReferentialContext = createContext();
export const ReferentialProvider = ({children}) => (
   <ReferentialContext.Provider value={useReducer(reducer, {status: "empty"})}>
       {children}
   </ReferentialContext.Provider>
);