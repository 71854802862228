import React from 'react';
import SortingOptionComponent from 'shared/components/list/SortingOptionComponent';

export default function SortingComponent(props) {

    const { columns, direction, onChange, value, label } = props;

    if (columns.length > 0) {
        return (
            <span className="field-wrap">
                <fieldset className="dropdown">
                    <legend className="text-normal">{label}</legend>
                    <div>
                        <ul>
                            {columns.map((option, index) => (
                                <li key={index}><SortingOptionComponent 
                                    index={index}
                                    option={option}
                                    checked={option.value === value}
                                    direction={direction}
                                    onChange={onChange}
                                /></li>
                            ))}
                        </ul>
                    </div>
                </fieldset>
            </span>
        );
    }
    
    return <></>;
}
