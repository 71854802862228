import ApiRequest from 'shared/services/ApiRequest';
import AbstractApi from 'shared/api/Abstract';

const AccessApi = {

    search: function(query, from, size, sorting, direction) {
        return AbstractApi.search("accesses/searches", query, from, size, sorting, direction);
    },

    create: function(data) {
        return ApiRequest.post("accesses", data).then(response => response.data);
    },

    update: function(id, data) {
        return ApiRequest.put("accesses/" + id, data).then(response => response.data);
    },

    bulkCreate: function(data) {
        return ApiRequest.post("accesses/bulk/create", data);
    },

    delete: function(id) {
        return AbstractApi.delete("accesses", id);
    },

    listWritableWatchsites: function(params = {limit: 999}) {
        return AbstractApi.list("accesses/watchsites/writable", params);
    },

    listReadableWatchsites: function(params = {limit: 999}) {
        return AbstractApi.list("accesses/watchsites/readable", params);
    },

    getUsersWithWriteAccess: function(watchsiteId) {
        return AbstractApi
            .search(
                "accesses/searches",
                {
                    "bool": {
                        "must": [
                            {"match": {"watchsite": watchsiteId}},
                            {"match": {"inherit_writeaccess": 1}},
                            {"match": {"user.active": 1}}
                        ]
                    }
                },
                0,
                100,
                "user.firstname",
                "asc"
            ).then(([accesses]) => {
                let p = [];
                accesses.forEach(a => {
                    p.push(a.user);
                });
                return p;
            });
    },
}
export default AccessApi;
