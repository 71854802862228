import React from 'react';

export default function SortingOptionComponent(props) {

    const { checked, option, onChange, index, direction } = props; 

    return (<>
        <input 
            type="radio"
            name="sortoptions"
            id={`sorting-${option.value}`}
            value={option.value}
            checked={checked}
            onChange={onChange} />                
        <label key={index} htmlFor={`sorting-${option.value}`}>{option.label}</label>
        {checked &&               
            <button type="button" onClick={onChange} className={`sorting ${direction}`}>
                <i className="icon icon-general-carat" aria-hidden="true"></i>
                <i className="icon icon-general-carat" aria-hidden="true"></i>
            </button>
        }
    </>);
}
