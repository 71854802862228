export default function TreeReferentialComponent(props) {

    const { items, className, value } = props;

    return (
        <ul className={className}>
            {items.map(item => {
                if (value.includes(item.value)) {
                    return (
                        <li key={item.value}>
                            {item.label}
                            {item.children.length > 0 && <TreeReferentialComponent value={value} items={item.children} />}
                        </li>
                    );
                }
                return null;
            })}
        </ul>
    )
}