const TreeUtils = {

    populateReadablePaths: function(tree) {
        this.addNodesReadablePaths(tree.nodes, ['Racine']);
        this.addWatchsitesReadablePaths(tree.watchsites, ['Racine']);

        return tree;
    },

    addNodesReadablePaths: function(nodes, paths = []) {
        nodes.map((node) => {
            let childsPath = [...paths];
            childsPath.push(node.label);
            this.addNodesReadablePaths(node.childs, childsPath);
            this.addWatchsitesReadablePaths(node.watchsites, childsPath);
            node.readablePaths = paths;

            return node;
        });

        return nodes;
    },

    addWatchsitesReadablePaths: function(watchsites, paths = []) {
        watchsites.map((watchsite) => {
            watchsite.readablePaths = paths;
            return watchsite;
        });

        return watchsites;
    },

    accountTreeFilterMapper: function(tree, enableNodeSelection = false, selection = []) {
        const watchsiteMapper = (watchsite) => {
            return {
                "label": watchsite.label,
                "value": "watchsite:" + watchsite.id,
                "checked": selection ? selection.includes("watchsite:" + watchsite.id) : false,
            } 
        };
        const nodeMapper = (node) => {
            let children = node.childs.map(nodeMapper);
            children = children.concat(node.watchsites.map(watchsiteMapper));
            return {
                "label": node.label,
                "value": "node:" + node.id,
                "children": children,
                "checked": selection ? selection.includes("node:" + node.id) : false,
                "disabled": !enableNodeSelection,
            }
        };

        return tree.nodes.map(nodeMapper).concat(tree.watchsites.map(watchsiteMapper));
    },

    findWatchsite: function(accountTree, watchsiteId) {
        let findedWatchsite = null;
        const watchsiteMapper = (watchsite) => {
            if (watchsite.id === watchsiteId) {
                findedWatchsite = {...watchsite};
            }
        };
        const nodeMapper = (node) => {
            node.watchsites.map(watchsiteMapper);
            node.childs.map(nodeMapper);
            return node;
        };

        accountTree.watchsites.map(watchsiteMapper);
        if (findedWatchsite === null) {
            accountTree.nodes.map(nodeMapper);
        }

        return findedWatchsite;
    }
}
export default TreeUtils;
