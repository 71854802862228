import rules from "rbacrules";
import { UserConsumerHook } from 'shared/stores/User';

const check = (rules, action, data) => {
    const [user] = UserConsumerHook();

    let hasAccess = false;
    user.roles.forEach(role => {
        const permissions = rules[role];
        if (permissions) {
            const staticPermissions = permissions.static;
            if (staticPermissions && staticPermissions.includes(action)) {
                // static rule not provided for action
                hasAccess = true;
            } else {
                const dynamicPermissions = permissions.dynamic;

                if (dynamicPermissions) {
                    const permissionCondition = dynamicPermissions[action];
                    if (permissionCondition) {
                        hasAccess = hasAccess || permissionCondition(user, data);    
                    }                    
                }
                        
            }
        }
    });
    return hasAccess;
};

export function canPerform(action, data) {
    return check(rules, action, data);
}

export function denyIfCantPerform(props, action, data) {
    if(canPerform(action, data) === false) {
        props.history.push('/denied');
    }
}

const Can = props =>
    check(rules, props.perform, props.data)
        ? props.children || props.yes()
        : props.no();

Can.defaultProps = {
    yes: () => null,
    no: () => null
};

export default Can;