import ApiRequest from 'shared/services/ApiRequest';
import AbstractApi from 'shared/api/Abstract';

const RequirementApi = {
    
    search: function(query, from, size, sorting, direction) {
        return AbstractApi.search("requirements/searches?useSolr", query, from, size, sorting, direction);
    },

    get: function(id) {
        return AbstractApi.get("requirements", id);
    },

    getSections: function(sheet) {
        return ApiRequest.get("requirementssections/tree/" + sheet).then(response => response.data);
    },

    save: function(data) {
        return AbstractApi.save("requirements", data);
    },

    saveSection: function(data) {
        return AbstractApi.save("requirementssections", data);
    },

    bulkUpdate: function(ids, data) {
        return ApiRequest.patch(
            "requirements/bulk/update",
            {
                "ids": ids,
                "data": data
            }
        );
    },

    updatePosition: function(id, position) {
        return ApiRequest
            .patch("requirements/update/position/" + id, {"position": position})
            .then(response => response.data);
    }
}
export default RequirementApi;
