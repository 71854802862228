import axios from 'axios';
import queryString from 'query-string';
import _ from 'lodash';

let backendUrl = process.env.REACT_APP_BACKEND_URL;
if (document.location.pathname.substring(0, 5) === "/oidc") {
    backendUrl = process.env.REACT_APP_FID_BACKEND_URL;
}

axios.interceptors.response.use(
    res => res,
    err => {
        if (err.request.responseURL !== backendUrl + "api/" + process.env.REACT_APP_CONTEXT + "/users/current"
            && err.request.responseURL !== backendUrl + "api/" + process.env.REACT_APP_CONTEXT + "/export/checkfornew"
            && err.request.responseURL !== backendUrl + "api/" + process.env.REACT_APP_CONTEXT + "/export/readyamount"
        ) {
            if (err.response.status === 503) {
                sessionStorage.setItem("maintenance", err.response.data.maintenance);
                document.location.href = "/maintenance";
            }
            if (err.response.status === 401 
                && err.response.data.message === "JWT Token not found"
                && document.location.pathname !== "/login"
                && document.location.pathname !== "/logout"
            ) {
                sessionStorage.setItem("message", "Vous avez été déconnecté");
                document.location.href = "/login";
            }
            throw err;
        }
    }
);

let axiosInstance = axios.create({
    baseURL: backendUrl + "api/" + process.env.REACT_APP_CONTEXT + "/",
    headers: {
        'Content-Type': 'application/json'
    },
    withCredentials: true
});

axiosInstance.interceptors.response.use(
    res => res,
    err => {
        if (err.request.responseURL !== backendUrl + "api/" + process.env.REACT_APP_CONTEXT + "/users/current"
            && err.request.responseURL !== backendUrl + "api/" + process.env.REACT_APP_CONTEXT + "/export/checkfornew"
            && err.request.responseURL !== backendUrl + "api/" + process.env.REACT_APP_CONTEXT + "/export/readyamount"
        ) {
            if (err.response.status === 503) {
                sessionStorage.setItem("maintenance", err.response.data.maintenance);
                document.location.href = "/maintenance";
            }
            if ((
                    err.response.status === 401
                    || err.response.status === 500
                ) && (
                    err.response.data.message === "JWT Token not found"
                    || err.response.data.message === "User not active"
                )
                && document.location.pathname !== "/login"
                && document.location.pathname !== "/logout"
            ) {
                sessionStorage.setItem("message", "Vous avez été déconnecté");
                document.location.href = "/login";
            }
            throw err;
        }
    }
);

export default axiosInstance;

export function convertCriteriesToSearchQuery(criterias, filterType) {
    if (criterias) {
        let clauses = [];
        Object.entries(criterias).forEach(([criteria, value]) => {
            if (!_.isNil(filterType[criteria]) 
                && value !== null
                && value !== ""
                && !_.isEqual(value, [])
                && !_.isEqual(value, {start:null, end:null})
            ) {
                let sendingValue = value;
                // eslint-disable-next-line default-case
                switch (filterType[criteria].cast) {
                    case "int": sendingValue = parseInt(value); break;
                }

                if (filterType[criteria].type === "wildcard") {
                    sendingValue = "*" + value + "*";
                }
                if (filterType[criteria].type === "terms" && !Array.isArray(value)) {
                    sendingValue = [value];
                }
                if (filterType[criteria].type === "range") {
                    let newvalue = {};
                    if (value.start !== null && value.start !== "") {
                        newvalue.gte = value.start;
                    }
                    if (value.end !== null && value.end !== "") {
                        newvalue.lte = value.end;
                    }
                    sendingValue = newvalue;
                }
                if (filterType[criteria].type === "gte") {
                    let newvalue = {};
                    //filterType[criteria].type = "range";
                    newvalue.gte = _.isNil(filterType[criteria].forcevalue) ? value : filterType[criteria].forcevalue;
                    sendingValue = newvalue;                    
                }

                if (filterType[criteria].fields.length === 1) {
                    clauses.push({
                        [filterType[criteria].type]: {[filterType[criteria].fields[0]]: sendingValue}
                    });
                } else {
                    let orClauses = [];
                    filterType[criteria].fields.forEach(field => {
                        orClauses.push({
                            [filterType[criteria].type]: {[field]: sendingValue}
                        });
                    });
                    clauses.push({
                        "bool": {
                            "should": orClauses
                        }
                    });
                }                                    
            }
        });
        if (clauses.length > 0) {
            return {
                "bool": {
                    "must": clauses
                }
            };
        }
    }
    return {};
}

export function getApiListParams(sorting, direction, criterias, limit, page) {
    let params = {
        limit: limit
    };

    if (sorting) {
        params.sort = sorting;
    }
    if (sorting && direction === "desc") {
        params.desc = sorting;
    }
    if (page) {
        params.offset = (page - 1) * limit;
    }
    if (criterias) {
        Object.entries(criterias).forEach(([name, value]) => {
            if (value !== null) {
                params = Object.assign({}, params, {[name]: value});
            }
        });
    }
    return queryString.stringify(params);
}
