import _ from "lodash";

export function usePosition(screenid) {
    
    function initializePosition() {
        if (!_.isEmpty(sessionStorage.getItem(screenid + "-position")) && document.getElementById("main-content")) {
            document.getElementById("main-content").scrollTo({
                top: sessionStorage.getItem(screenid + "-position")
            })
        }
    }

    function getElementPosition(elementId, offset = 65) {
        return document.getElementById(elementId).getBoundingClientRect().top - offset;
    }

    function setPosition(newposition) {
        sessionStorage.setItem(screenid + "-position", newposition);
    }

    return [initializePosition, setPosition, getElementPosition];
}