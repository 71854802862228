import _ from 'lodash';
import { SHEET_STATE_VALIDATED, SHEET_STATE_UNNECESSARY } from "shared/data/SheetState";

const SheetUtils = {

    getLinkedSheets: function(sheet, orderField = 'major_text', orderType = 'desc') {
        return [
            ..._.orderBy(sheet.linked_sheet_importants, [orderField], [orderType]),
            ..._.orderBy(sheet.linked_sheet_others, [orderField], [orderType])
        ];
    },

    getSheetStatesWarning: function(sheet) {
        if (!sheet.is_private) {
            if (sheet.state_synthesis === SHEET_STATE_VALIDATED
                && sheet.state_technical !== SHEET_STATE_VALIDATED
                && sheet.state_technical !== SHEET_STATE_UNNECESSARY
            ) {
                return "Le commentaire technique est en cours de réalisation.";
            } else if (sheet.state_synthesis !== SHEET_STATE_VALIDATED
                && (
                    sheet.state_technical === SHEET_STATE_VALIDATED
                    || sheet.state_technical === SHEET_STATE_UNNECESSARY
                )
            ) {
                return "La synthése est en cours de réalisation.";
            } else if (sheet.state_synthesis !== SHEET_STATE_VALIDATED
                && sheet.state_technical !== SHEET_STATE_VALIDATED
                && sheet.state_technical !== SHEET_STATE_UNNECESSARY
            ) {
                return "La synthése et le commentaire technique sont en cours de réalisation.";
            }
        }
        return null;
    }
}
export default SheetUtils;