import { useState } from 'react';

export function useToggler(togglersList = {}) {
    const [togglers, setTogglers] = useState(togglersList);

    function toggle(togglerId) {
        setTogglers(Object.assign({}, togglers, {[togglerId]: !togglers[togglerId]}));
    }

    function close(togglerId) {
        setTogglers(Object.assign({}, togglers, {[togglerId]: false}));
    }

    function open(togglerId) {
        setTogglers(Object.assign({}, togglers, {[togglerId]: true}));
    }

    function closeAll() {
        const newTogglers = Object.assign({}, togglers);
        for (const [toggleId] of Object.entries(togglers)) {
            newTogglers[toggleId] = false;
        }
        setTogglers(newTogglers);
    }

    function get(toggleId) {
        if (!togglers[toggleId]) {
            return false;
        }
        return togglers[toggleId];
    }

    function openAll() {
        const newTogglers = Object.assign({}, togglers);
        for (const [toggleId] of Object.entries(togglers)) {
            newTogglers[toggleId] = true;
        }
        setTogglers(newTogglers);
    }

    function reset() {
        setTogglers([]);
    }

    return [togglers, toggle, openAll, closeAll, open, close, get, reset];
}