import _ from 'lodash';
import InternalUserApi from 'shared/api/InternalUser';
import { compileDataToSelectOptions } from 'shared/services/Utils';

function InternalUserAutocomplete(criterias, callback, orderingField = 'lastname', orderingDirection = 'ASC') {

    if (criterias.keyword === "" || criterias.keyword.length < 3) {
        callback([]);
    }

    let queryArgs = {
        "bool": {
            "must": [
                {
                    "bool": {
                        "should": [
                            {"wildcard": {"firstname": criterias.keyword + "*"}},
                            {"wildcard": {"lastname": criterias.keyword + "*"}},
                            {"wildcard": {"email": criterias.keyword + "*"}}
                        ]
                    }
                },
            ]
        }
    };

    if(criterias.role) {
        queryArgs["bool"]["must"].push(
            {"terms":
                {
                    "roles": [criterias.role]
                }
            }
        );
    }

    InternalUserApi
        .search(queryArgs, 0, 25, orderingField, orderingDirection)
        .then(([users]) => {
            callback(
                compileDataToSelectOptions(users, 'id', ['firstname', 'lastname'])
            );
        });
}

export default _.debounce(InternalUserAutocomplete, 300);