import { React, toast, DefaultLayout, useEffect, useState } from 'commons';
import { FileUtils, preventDefault } from 'shared/services';
import { useForm } from 'shared/hooks';
import { FieldComponent, PageLoadingComponent } from 'shared/components';
import ObligationGeneratorApi from 'shared/api/ObligationGenerator';

export default function ObligationGeneratorForm() {

    const [data,,, setValue, setData,,] = useForm({});
    const [loading, setLoading] = useState(true);
    const [questions, setQuestions] = useState([]);
    const [hookQuestions] = useState({
        0.5: {
            questions: [
                {
                    dataName: "ERP",
                    label: "Êtes-vous un établissement recevant du public (E.R.P.) "
                }
            ]
        },
        1.5: {
            questions: [
                {
                    dataName: "IGH",
                    label: "Êtes-vous un immeuble de grande hauteur (I.G.H) "
                }
            ]
        },
        2.5: {
            questions: [
                {
                    dataName: "TRAVAIL",
                    label: "Souhaitez-vous avoir les obligations d'un lieu de travail "
                }
            ]
        },
        3.5: {
            questions: [
                {
                    dataName: "MAISON",
                    label: "Etes-vous un batiment à usage d'habitation "
                }
            ]
        },
        4.5: {
            questions: [
                {
                    dataName: "DPEG",
                    label: "Souhaitez-vous avoir les obligations liées aux diagnostics immobilier et énergie "
                },
                {
                    dataName: "DPEMAISON",
                    label: "Souhaitez-vous avoir les obligations liées aux diagnostics immobilier et énergie pour un logement "
                }
            ]
        }
    });
    const sectionTitles = {
        0.5: 'Typologie de batiment',
        1: 'ERP : Vos activités et équipements',
        1.5: 'Typologie de batiment',
        2: 'IGH - Vos équipements',
        2.5: 'Typologie de batiment',
        3: 'Locaux de Travail : Vos activités, équipements et ICPE',
        3.5: 'Typologie de batiment',
        4: '',
        4.5: '',
        5: 'Vos déchets et autres'
    }
    const [step, setStep] = useState(0.5);
    const availableSteps = [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5];

    function canDisplayStep(stepToCheck) {
        let canDisplayStep = true;
        if (Number.isInteger(stepToCheck) && stepToCheck !== 5) {
            hookQuestions[stepToCheck - 0.5].questions.map((question) => {
                if (data[question.dataName] !== 1) {
                    canDisplayStep = false;
                }
                return question;
            });
        }
        return canDisplayStep;
    }

    function getPreviousStep(currentStep) {
        let stepIndex = availableSteps.indexOf(currentStep);
        if (stepIndex === 0 || stepIndex === 1) {
            return availableSteps[0];
        }
        let previousStep = availableSteps[stepIndex - 1];
        if (!canDisplayStep(previousStep)) {
            previousStep = availableSteps[stepIndex - 2];
        }
        return previousStep;
    }

    function getNextStep(currentStep) {
        let stepIndex = availableSteps.indexOf(currentStep);
        let nextStep = availableSteps[stepIndex + 1];
        if (!canDisplayStep(nextStep)) {
            nextStep = availableSteps[stepIndex + 2];
        }
        return nextStep;
    }

    function populateData(questions) {
        let values = {
            "ERP": 0,
            "IGH": 0,
            "TRAVAIL": 0,
            "MAISON": 0,
            "DPEG": 0,
            "DPEGMAISON": 0
        };
        for (const [, stepQuestions] of Object.entries(questions)) {
            stepQuestions.forEach(element => {
                values[element.code] = 0;
            });
        }
        setData({...values});
    }

    function load() {
        ObligationGeneratorApi
            .getAllQuestions()
            .then(questions => {
                setQuestions(questions);
                populateData(questions);
                setLoading(false);
            })
            .catch((e) => {
                toast.error("Une erreur est survenue lors de la récupération des questions");
            });
    }

    function generateExport() {
        const fileName = FileUtils.getFilePrefix() + "-ObligationsRégl.xlsx";
        ObligationGeneratorApi
            .export(data, fileName)
            .then(responseData => {
                toast.success("L'export a été initié, vous serez notifié lorsqu'il sera disponible.");
            })
            .catch(() => {
                toast.error("Erreur durant la création de l'export.")
            });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => load(), []);

    if (loading) {
        return <PageLoadingComponent label={`Générateur d'obligations`} />
    } else {
        return (
            <DefaultLayout title={`Générateur d'obligations`}>
                <section>
                    <h1>Générateur d'obligations</h1>
                    <section>
                        <form className="form obligations-form" onSubmit={(e) => preventDefault(e)}>
                            <section className="row">
                                <div className="col text-left">
                                    <button
                                        className="btn btn-bleu-4"
                                        disabled={step === availableSteps[0]}
                                        onClick={() => setStep(getPreviousStep(step))}
                                    >
                                        Retour
                                    </button>
                                </div>
                                <div className="col text-right">
                                    <button
                                        className="btn btn-bleu-4"
                                        disabled={step === availableSteps[availableSteps.length - 1]}
                                        hidden={step === availableSteps[availableSteps.length - 1]}
                                        onClick={() => setStep(getNextStep(step))}
                                    >
                                        Suivant
                                    </button>
                                    <button
                                        className="btn btn-primary"
                                        disabled={step !== availableSteps[availableSteps.length - 1]}
                                        hidden={step !== availableSteps[availableSteps.length - 1]}
                                        onClick={() => generateExport()}
                                    >
                                        Générer mes obligations
                                    </button>
                                </div>
                            </section>
                            <section className="row text-center bloc">
                                <div class="col text-center">
                                    <h2>{sectionTitles[step]}</h2>
                                </div>
                            </section>
                            {!Number.isInteger(step) && <>
                                {hookQuestions[step].questions.map((question) => {
                                    return (
                                        <section className="bloc">
                                            <div className="bloc table">
                                                <FieldComponent
                                                    label={question.label}
                                                    name={question.dataName}
                                                    type="radio"
                                                    value={data[question.dataName]}
                                                    options={[
                                                        {value: 1, label: "Oui"},
                                                        {value: 0, label: "Non"}
                                                    ]}
                                                    onChange={value => setValue(question.dataName, parseInt(value))}
                                                />
                                            </div>
                                        </section>
                                    )
                                })}
                            </>}
                            {Number.isInteger(step) && <>
                                {questions[step].length > 0 && <>
                                    {questions[step].map((question) => {
                                        return (
                                            <section className="bloc">
                                                <div className="bloc table">
                                                    <FieldComponent
                                                        label={question.label}
                                                        name={question.code}
                                                        type="radio"
                                                        value={data[question.code]}
                                                        options={[
                                                            {value: 1, label: "Oui"},
                                                            {value: 0, label: "Non"}
                                                        ]}
                                                        onChange={value => setValue(question.code, parseInt(value))}
                                                    />
                                                </div>
                                            </section>
                                        )
                                    })}
                                </>}
                                {questions[step].length <= 0 && <>
                                    <section className="bloc">
                                        <div className="bloc text-center">
                                            <p>Aucune question pour cette section</p>
                                        </div>
                                    </section>
                                </>}
                            </>}
                        </form>
                    </section>
                </section>
            </DefaultLayout>
        );
    }
}