export const modalCustomStyles = {
    overlay: {
        backgroundColor: 'rgba(255, 255, 255, 0.90)'
    },
    content : {}
};

export const smallScrollableModalCustomStyles = {
    overlay: {
        backgroundColor: 'rgba(255, 255, 255, 0.90)'
    },
    content : {
        width: "800px",
        left: "50%",
        right: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, 0%)"
    }
};

export const smallModalCustomStyles = {
    overlay: {
        backgroundColor: 'rgba(255, 255, 255, 0.90)'
    },
    content : {
        width: "800px",
        bottom: "auto",
        left: "50%",
        right: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, 0%)"
    }
};

export const smallOverflowVisibleModalCustomStyles = {
    overlay: {
        backgroundColor: 'rgba(255, 255, 255, 0.90)'
    },
    content : {
        width: "800px",
        bottom: "auto",
        left: "50%",
        right: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, 0%)",
        overflow: "visible"
    }
};

export const mediumModalCustomStyles = {
    overlay: {
        backgroundColor: 'rgba(255, 255, 255, 0.90)'
    },
    content : {
        width: "1024px",
        bottom: "auto",
        left: "50%",
        right: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, 0%)"
    }
}

export const mediumModalBorderlessCustomStyles = {
    overlay: {
        backgroundColor: 'rgba(255, 255, 255, 0.90)'
    },
    content : {
        padding: 0,
        width: "1024px",
        bottom: "auto",
        left: "50%",
        right: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, 0%)"
    }
}