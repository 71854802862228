import ApiRequest from 'shared/services/ApiRequest';
import AbstractApi from 'shared/api/Abstract';

const NodeApi = {

    search: function(query, from, size, sorting, direction) {
        return AbstractApi.search("nodes/searches", query, from, size, sorting, direction);
    },

    get: function(id) {
        return AbstractApi.get("nodes", id);
    },
    
    save: function(data) {
        return AbstractApi.save("nodes", data);
    },

    bulkUpdate: function(ids, data) {
        return ApiRequest.patch(
            "nodes/bulk/update",
            {
                "ids": ids,
                "data": data
            }
        );
    },

    delete: function(id) {
        return AbstractApi.delete("nodes", id);
    }
}
export default NodeApi;
