import _ from 'lodash';
import { UserConsumerHook } from 'shared/stores/User';

export function useSecurity() {
    const [user, userDispatch] = UserConsumerHook();

    function setUser(data) {
        userDispatch({
            type: 'setUser',
            user: data,
        });
    }

    function granted(roles) {
        if (_.isUndefined(user.roles)) {
            return false;
        }
        if (isAdmin) {
            return true;
        }
        let isGranted = false;
        roles.forEach(role => {
            isGranted = isGranted || hasRole(role);
        });
        return isGranted;
    }

    function hasRole(role) {
        return user.roles.includes(role);
    }

    function isAdmin() {
        if (hasRole("ROLE_SUPER_ADMIN") || hasRole("ROLE_ADMIN")) {
            return true;
        }
    }

    return [user, granted, setUser, hasRole, isAdmin];
}