export const typeLabels = {
    caselaw: 'Jurisprudences',
    clientspecificmodule: 'Mes documents privés',
    drafttext: 'Projets de texte',
    form: 'Formulaires / déclarations (CERFA et notice)',
    news: 'Actualités documentaires',
    newsflash: 'Flashs d\'informations',
    newsletter: 'Newsletters',
    regulatorycontrol: 'Contrôles règlementaires',
    regulatoryflowchart: 'Organigrammes règlementaires',
    sanction: 'Sanctions',
    thematicfile: 'Dossiers et fiches thématiques',
};