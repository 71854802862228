import BarLoader from "react-spinners/BarLoader";

export default function LoadButton(props) {

    const { disabled, loading, label, displayIcon, iconClass, name, id, onClick, className, loaderWidth } = props; 

    function preOnClick(event) {
        if(onClick) {
            event.preventDefault();
            event.stopPropagation();
            onClick(event);
        }
    }

    return (
        <button disabled={disabled || (loading ? "disabled" : null)} id={id || null} type={onClick ? "button" : "submit"} onClick={(event) => preOnClick(event)} className={className || "btn btn-primary"} name={name || null}>
            {displayIcon && <><i className={iconClass} aria-hidden="true"></i>&nbsp;</>}
            {label}
            <BarLoader loading={loading} width={loaderWidth} color="#ffffff" css="display:block;margin:0px auto;" />
        </button>
    )
}


LoadButton.defaultProps = {
    iconClass: "icon-boutons-enregistrer",
    displayIcon: true,
    label: "Valider",
    loaderWidth: 100
};