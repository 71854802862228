import ApiRequest from 'shared/services/ApiRequest';
import AbstractApi from 'shared/api/Abstract';

const InternalUserApi = {

    get: function(id) {
        return AbstractApi.get("internalusers", id);
    },
    
    save: function(data) {
        return AbstractApi.save("internalusers", data);
    },

    list: function(role = null) {
        let params = {};

        if (role) {
            params.role = role;
        }
        return AbstractApi.list("internalusers", params);
    },

    search: function(query, from, size, sorting, direction) {
        return AbstractApi.search("internalusers/searches", query, from, size, sorting, direction);
    },

    export: function(query) {
        return AbstractApi.export("internalusers", query);
    },

    assignWatchsite: function(user, watchsites) {
        return ApiRequest
            .post(
                "internalusers/assign-watchsites",
                {
                    "user": user,
                    "watchsites": watchsites
                }
            );
    },

    unassignWatchsite: function(user, watchsites) {
        return ApiRequest
            .post(
                "internalusers/unassign-watchsites",
                {
                    "user": user,
                    "watchsites": watchsites
                }
            );
    }
}
export default InternalUserApi;
