import ApiRequest from 'shared/services/ApiRequest';

const FileApi = {

    upload: function(endpoint, files) {
        const formData = new FormData();
        files.forEach(file => {
            formData.append('files[]', file);
        })
        return ApiRequest.post(
            endpoint,
            formData,
            {
                headers: {'Content-Type': 'multipart/form-data'}
            }
        ).then(response => response.data);
    },

    download: function(hash) {
        return ApiRequest
            .get("files/" + hash + "/download", {responseType: 'blob'})
            .then(response => response.data);
    }
}
export default FileApi;