import DOMPurify from 'dompurify';

export default function HtmlComponent(props) {

    const { children } = props; 
    
    if (children) {
        return (
            <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(children)}} />
        );
    }

    return null;
}