import ApiRequest from 'shared/services/ApiRequest';

const InvitationApi = {

    bulkCreate: function(users, context) {
        return ApiRequest.post(
            "invitations/bulk/create",
            {
                "users": users,
                "context": context
            }
        );
    }
}
export default InvitationApi;
