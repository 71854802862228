import AbstractApi from 'shared/api/Abstract';
import ApiRequest from 'shared/services/ApiRequest';
import FileApi from 'shared/api/File';

const PREFIX = "hotlines";

const HotlineApi = {

    get: function(id) {
        return AbstractApi.get(PREFIX, id);
    },
    
    search: function(query, from, size, sorting, direction) {
        return AbstractApi.search(PREFIX + "/searches", query, from, size, sorting, direction);
    },

    favorite: function(id) {
        return ApiRequest.post(PREFIX + "/" + id + "/favorite").then(res => res.data.result);
    },
    
    export: function(query, fileName) {
        return AbstractApi.export(PREFIX, query, '', fileName);
    },

    uploadAttachments: function(files) {
        return FileApi.upload(PREFIX + "/upload/attachments", files);
    },
    
    save: function(data) {
        return AbstractApi.save(PREFIX, data);
    },
}

export default HotlineApi;