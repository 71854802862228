import AbstractApi from 'shared/api/Abstract';

const HistoryApi = {

    search: function(query, from, size, sorting, direction, type, id) {
        return AbstractApi.search("logs/" + type + "/" + id + "/searches", query, from, size, sorting, direction);
    }
}

export default HistoryApi;
