import { React, Link, Prompt, useHistory, _, toast, DefaultLayout, useCallback, useEffect, useState, useContext, Modal } from 'commons';
import LinkedSheetPicker from './form/LinkedSheetPicker';
import WeblinkPicker from './form/WeblinkPicker';
import { getSheetStatus, SHEET_TO_RULE, SHEET_ARCHIVED, SHEET_TO_DO } from 'data/SheetStatus';
import { getSheetTodo, REFERENTIALS, getDegree, SHEET_ACTION, getSheetActionName, getSheetState, getSheetStateActionAvailable, SHEET_STATE_TYPE_SYNTHESIS, SHEET_STATE_TODO, SHEET_STATE_TODECIDE, SHEET_STATE_TYPE_TECHNICAL } from 'shared/data';
import { InternalUserApi, SheetApi } from 'shared/api'
import { SheetDeadline } from 'shared/views';
import { Can, canPerform, denyIfCantPerform, compileErrors, formatDate, boolToInt, compileDataToSelectOptions, compileDataToSelectOption, mediumModalCustomStyles } from 'shared/services';
import { useToggler, useForm } from "shared/hooks";
import { ReferentialContext } from 'shared/stores';
import { HtmlComponent, LoadButton, SidebarComponent, FieldComponent, FileComponent, ToggableBlocComponent, StatusComponent, PageLoadingComponent } from 'shared/components';
import Affectations from 'views/sheet/detail/Affectations';

export default function SheetForm(props) {

    denyIfCantPerform(props, "sheet:write");
    
    const [referentialContext] = useContext(ReferentialContext);
    const [togglers, toggle] = useToggler({
        'informations-generales': true,
        'niveau-synthese': true,
        'niveau-technique': true,
        'domaines': true,
        'relations': true,
        'depeches': true,
        'taches': true,
    });

    const history = useHistory();

    // eslint-disable-next-line
    const [data, errors, setErrors, setValue, setData, reloadOrClose, formHasModifications] = useForm({
        id: null,
        internal_comment: '',
        title: '',
        reglementary_text: null,
        legifrance_source: '',
        nor: '',
        source_number: '',
        version_number: '',
        transmitter: null,
        text_number: '',
        text_type: null,
        source: null,
        consolidated_text: 0,
        repealed_text: 0,
        publication_date: null,
        text_date: null,
        text_update_date: new Date(),
        updated_at: new Date(),
        created_at: new Date(),
        text_codification: null,
        librarian: null,
        todos: [0],
        synthesis: '',
        descriptors: [],
        technical_comment: '',
        expert: null,
        sanction: 'Aucune sanction.',
        cut_into_requirements: 0,
        major_text: 0,
        deadlines: [],
        text_impacts: [],
        corporal_impact_degree: 0,
        media_impact_degree: 0,
        legal_impact_degree: 0,
        financial_impact_degree: 0,
        involved_actors: [],
        involved_functions: [],
        consultant_comment: '',
        categories: [],
        main_domain: null,
        main_subdomain: null,
        main_theme: null,
        linked_sheet_importants: [],
        linked_sheet_others: [],
        weblinks: [],
        attachments: [],
        confidential_account: null,
        dispatch: '',
        add_to_dispatch: 1,
        dispatch_image: '',
        document_todo: '',
        periodicity: '',
        control_todo: '',
        is_private: false,
    });

    const [stateSynthesis, setStateSynthesis] = useState({
        force: false,
        id: null,
        value: null,
    });
    const [stateTechnical, setStateTechnical] = useState({
        force: false,
        id: null,
        value: null,
    });
    const [availableDomains, setAvailableDomains] = useState([]);
    const [availableSubdomains, setAvailableSubdomains] = useState([]);
    const [availableThemes, setAvailableThemes] = useState([]);
    const [experts, setExperts] = useState([]);
    const [librarians, setLibrarians] = useState([]);
    const [sheet, setSheet] = useState({
        status: SHEET_TO_RULE,
        state_synthesis: SHEET_STATE_TODO,
        state_technical: SHEET_STATE_TODECIDE,
        is_private: false,
    });
    const [isLoad, setIsLoad] = useState(!!props.match.params.id);
    const [saving, setSaving] = useState("");
    const [affectationsOpened, openingAffectations] = useState(false);
    
    // referential
    const textTypes = compileDataToSelectOptions(referentialContext[REFERENTIALS.REFERENTIAL_TEXT_TYPE], 'id', 'value');
    const textImpacts = compileDataToSelectOptions(referentialContext[REFERENTIALS.REFERENTIAL_TEXT_IMPACT], 'id', 'value');
    const sources = compileDataToSelectOptions(referentialContext[REFERENTIALS.REFERENTIAL_SOURCE], 'id', 'value');
    const transmitters = compileDataToSelectOptions(referentialContext[REFERENTIALS.REFERENTIAL_TRANSMITTER], 'id', 'value');
    const involvedActors = compileDataToSelectOptions(referentialContext[REFERENTIALS.REFERENTIAL_INVOLVED_ACTOR], 'id', 'value');
    const involvedFunctions = compileDataToSelectOptions(referentialContext[REFERENTIALS.REFERENTIAL_INVOLVED_FUNCTION], 'id', 'value');
    const descriptors = compileDataToSelectOptions(referentialContext[REFERENTIALS.REFERENTIAL_DESCRIPTORS], 'id', 'value');
    const textCodifications = compileDataToSelectOptions(referentialContext[REFERENTIALS.REFERENTIAL_TEXT_CODIFICATION], 'id', 'value');
    const domainTree = _.cloneDeep(referentialContext["tree"]);

    const loadDependencies = useCallback(() => {
        InternalUserApi.search({"bool":{"must":[{"match":{"active":1}},{"terms":{"roles":["ROLE_EXPERT"]}}]}}).then(([u]) => setExperts(compileDataToSelectOptions(u, 'id', ['lastname', 'firstname'])));
        InternalUserApi.search({"bool":{"must":[{"match":{"active":1}},{"terms":{"roles":["ROLE_LIBRARIAN"]}}]}}).then(([u]) => setLibrarians(compileDataToSelectOptions(u, 'id', ['lastname', 'firstname'])));
    }, []);

    useEffect(() => {
        const sheetId = props.match.params.id;
        if (sheetId) {
            SheetApi
                .get(sheetId)
                .then(freshSheet => {
                    setSheet(freshSheet);
                    setData(convertFreshSheet(freshSheet));
                    setIsLoad(false);
                })
                .catch(response => {
                    toast.error("Une erreur est survenue lors de la récupération de la fiche");
                    history.goBack();
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(loadDependencies, []);

    useEffect(() => {
        let newDomains = [];
        let newSubDomains = [];
        let newThemes = [];

        const mapper = (item, parent = null) => {
            if (item.children) {
                item.children = item.children.map(i => mapper(i, item));
            }
            if (data.categories.includes(item.value)) {
                // eslint-disable-next-line default-case
                switch (item.type) {
                    case 1: 
                        if (!newDomains.includes(item) || data.main_domain === item.value) {
                            newDomains.push(item);
                        }
                        break;
                    case 2: 
                        if ((!newSubDomains.includes(item) && parent.value === data.main_domain) || data.main_subdomain === item.value) {
                            newSubDomains.push(item);
                        }
                        break;
                    case 3: 
                        if ((!newThemes.includes(item) && parent.value === data.main_subdomain) || data.main_theme === item.value) {
                            newThemes.push(item);
                        }
                        break;
                }
            } else {
                // eslint-disable-next-line default-case
                switch (item.type) {
                    case 1: 
                        if (data.main_domain === item.value) {
                            newDomains.push(item);
                        }
                        break;
                    case 2: 
                        if (data.main_subdomain === item.value) {
                            newSubDomains.push(item);
                        }
                        break;
                    case 3: 
                        if (data.main_theme === item.value) {
                            newThemes.push(item);
                        }
                        break;
                }
            }
            return item;
        }; 

        domainTree.map(mapper);

        setAvailableDomains(newDomains);
        setAvailableSubdomains(newSubDomains);
        setAvailableThemes(newThemes);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.categories, data.main_domain, data.main_subdomain]);

    function convertFreshSheet(freshSheet) {
        let sheetData = {...freshSheet};

        if (sheetData.confidential_account) {
            sheetData.confidential_account = compileDataToSelectOption(sheetData.confidential_account, 'id', 'name');
        }

        if (sheetData.linked_sheet_importants) {
            sheetData.linked_sheet_importants = compileDataToSelectOptions(sheetData.linked_sheet_importants, 'id', 'title');
        }
        
        if (sheetData.linked_sheet_others) {
            sheetData.linked_sheet_others = compileDataToSelectOptions(sheetData.linked_sheet_others, 'id', 'title');
        }

        if (sheetData.expert)  sheetData.expert = sheetData.expert.id;
        if (sheetData.librarian)  sheetData.librarian = sheetData.librarian.id;
        if (sheetData.transmitter) sheetData.transmitter = sheetData.transmitter.id;
        if (sheetData.source) sheetData.source = sheetData.source.id;
        if (sheetData.main_domain) sheetData.main_domain = sheetData.main_domain.id;
        if (sheetData.main_subdomain) sheetData.main_subdomain = sheetData.main_subdomain.id;
        if (sheetData.main_theme) sheetData.main_theme = sheetData.main_theme.id;
        if (sheetData.text_codification) sheetData.text_codification = sheetData.text_codification.id;
        if (sheetData.text_types && sheetData.text_types.length) sheetData.text_type = sheetData.text_types[0].id;
        if (sheetData.involved_functions) sheetData.involved_functions = sheetData.involved_functions.map(elem => elem.id);
        if (sheetData.text_impacts) sheetData.text_impacts = sheetData.text_impacts.map(elem => elem.id);
        if (sheetData.involved_actors) sheetData.involved_actors = sheetData.involved_actors.map(elem => elem.id);
        if (sheetData.descriptors) sheetData.descriptors = sheetData.descriptors.map(elem => elem.id);
        if (sheetData.categories) sheetData.categories = sheetData.categories.map(elem => elem.id);

        sheetData.repealed_text = boolToInt(sheetData.repealed_text);
        sheetData.major_text = sheetData.repealed_text === 1 ? 0 : boolToInt(sheetData.major_text);
        sheetData.consolidated_text = boolToInt(sheetData.consolidated_text);
        sheetData.add_to_dispatch = boolToInt(sheetData.add_to_dispatch);
        
        if (sheetData.publication_date) sheetData.publication_date = new Date(sheetData.publication_date);
        if (sheetData.text_date) sheetData.text_date = new Date(sheetData.text_date);
        if (sheetData.text_update_date) sheetData.text_update_date = new Date(sheetData.text_update_date);

        if (sheetData.deadlines) {
            sheetData.deadlines.forEach(deadline => {
                deadline.date = new Date(deadline.date);
            });
        }
        return sheetData;
    }

    function submit(event) { 
        event.preventDefault();

        if (!data.publication_date && !window.confirm("Vous n'avez pas spécifié de date de publication. Les fiches sans date de publication ne remontent pas dans les recherches en cas de filtre sur la date de parution. Souhaitez-vous continuer ?")) {            
            return;
        }

        const submitButton = event.nativeEvent.submitter.name;
        const actionId = SHEET_ACTION[submitButton];

        let formData = {...data};
        formData.action = actionId;

        formData.attachments = data.attachments || [];
        formData.text_types = data.text_type ? [data.text_type] : null;
        formData.reglementary_text = data.reglementary_text ? data.reglementary_text.hash : null;
        formData.dispatch_image = data.dispatch_image ? data.dispatch_image.hash : null;
        formData.linked_sheet_importants = data.linked_sheet_importants.map(s => s.value);
        formData.linked_sheet_others = data.linked_sheet_others.map(s => s.value);
        formData.confidential_account = data.confidential_account ? data.confidential_account.value : null;
        formData.major_text = data.repealed_text === 1 ? 0 : parseInt(data.major_text);
        
        SheetApi
            .save(formData)
            .then(([message, freshSheet]) => {
                setSheet(freshSheet);
                setData(convertFreshSheet(freshSheet));
                setStateSynthesis(stateSynthesis => { 
                    stateSynthesis.force = false; 
                    return stateSynthesis
                })
                setStateTechnical(stateTechnical => {
                    stateTechnical.force = false;
                    return stateTechnical
                })

                _.delay(() => {
                    setErrors({});
                    setSaving("");
                    toast.success(message);
                    reloadOrClose(event, props, "sheets", freshSheet.id, true);
                }, 1000); // solr indexation                
            })
            .catch(error => {
                setSaving("");
                if (error.response) {
                    const errordata = error.response.data;
                    toast.error(errordata.message);
                    if (errordata.errors) {
                        let newErrors = {};
                        Object.entries(errordata.errors).forEach(([index, itemerror]) => {
                            let newError = {[itemerror.property_path]: itemerror.message};
                            if(index === "deadlines") {
                                let deadlineErrors = {};
                                Object.entries(itemerror).forEach(([dlindex, dlErrors]) => {
                                    deadlineErrors[dlindex] = compileErrors(dlErrors)
                                });
                                newError = {deadlines: deadlineErrors};
                            }
                            newErrors = {...newErrors, ...newError};
                        });
                        setErrors(newErrors);
                    }
                }
            });
        
        setSaving(submitButton);
    }

    function categoriesChange(values) {
        let newValues = [];

        const mapper = (item) => {
            if (item.children) {
                item.children = item.children.map(mapper);
                item.children.forEach(child => {
                    if (!newValues.includes(item.value)
                        && (values.includes(child.value) || newValues.includes(child.value))
                    ) {
                        newValues.push(item.value);
                    }
                });
            }
            if (values.includes(item.value) && !newValues.includes(item.value)) {
                newValues.push(item.value);  
            }

            return item;
        }; 

        domainTree.map(mapper);

        let newData = {...data};
        newData.categories = newValues;

        if (data.main_domain && !newValues.includes(data.main_domain)) {
            newData.main_domain = null;
        }
        if (data.main_subdomain && !newValues.includes(data.main_subdomain)) {
            newData.main_subdomain = null;
        }
        if (data.main_theme && !newValues.includes(data.main_theme)) {
            newData.main_theme = null;
        }

        setData(newData);
    }

    function goBack() {
        let path = localStorage.getItem("sheetFormReferer");
        if (!path) {
            path = "/sheets";
        }
        history.push(path)
    }

    let pageName = !!props.match.params.id ? "Edition d'une fiche" : "Nouvelle fiche";

    if (isLoad === true) {
        return <PageLoadingComponent label={pageName} />
    }

    if (sheet.id) {
        pageName = <>Edition de la fiche N° {sheet.id} : <HtmlComponent>{sheet.title}</HtmlComponent></>;
    }

    return (
        <DefaultLayout screen="E15" title={pageName}>
            <Prompt
                when={formHasModifications()}
                message="Vous avez des modifications non enregistrées, voulez-vous vraiment quitter ?"
            />
            <section className="sidebar">
                <h1>{pageName}</h1>

                <SidebarComponent
                    label={!!props.match.params.id ? "Edition d'une fiche" : "Nouvelle fiche"}
                    links={[
                        {anchor: "#informations-generales", label: "Informations générales", active: true},
                        {anchor: "#niveau-synthese", label: "Niveau synthèse", active: true},
                        {anchor: "#niveau-technique", label: "Niveau technique", active: true},
                        {anchor: "#domaines", label: "Domaines / Sous domaines / Thèmes", active: true},
                        {anchor: "#relations", label: "Relations", active: true},
                        {anchor: "#depeches", label: "Dépêches", active: true},
                        {anchor: "#taches", label: "Tâches clients", active: true},
                    ]}
                />
                <section>
                    <form onSubmit={submit} id="form-sheet" className="form">
                        <ToggableBlocComponent
                            label="Informations générales"
                            id="informations-generales"
                            toggled={togglers["informations-generales"]}
                            toggle={toggle}
                        >
                            <div className="bg-blanc">
                                <div className="row">
                                    {data.id && (
                                        <React.Fragment>
                                            <div className="col-md-6">
                                                <div className="flex-label">
                                                    <span className="label">ID de la fiche&nbsp;:</span>
                                                    <span>{sheet.id}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="flex-label">
                                                    <span className="label">Date & heure de création&nbsp;:</span>
                                                    <span>{formatDate(sheet.created_at, true)}</span>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )}
                                    <div className="col-md-6">
                                        <div className="flex-label">
                                            <span className="label">Statut de la fiche&nbsp;:</span>
                                            <StatusComponent
                                                options={getSheetStatus()}
                                                value={sheet.status}
                                                size="large"
                                            />
                                        </div>
                                    </div>                                    
                                    <div className="col-md-6">
                                        <div className="flex-label">
                                            {sheet.legifrance_source && <>
                                                <span className="label">Source Légifrance&nbsp;:</span>
                                                <span>{sheet.legifrance_source}</span>
                                            </>}
                                        </div>
                                    </div>
                                    {data.id && (
                                        <div className="col-md-6 valign-center">
                                            <div className="flex-label">
                                                <span className="label">Découpée en exigence&nbsp;:</span>
                                                <span>{sheet.cut_into_requirements ? 'Oui' : 'Non'}</span>
                                            </div>
                                        </div>
                                    )}
                                    <div className="col-md-6 valign-center">
                                        <FieldComponent
                                            label="Texte majeur"
                                            name="major_text"
                                            type="radio"
                                            value={data.repealed_text === 1 ? 0 : data.major_text}
                                            error={errors.repealed_text}
                                            disabled={data.repealed_text}
                                            options={[
                                                {value: 1, label: "Oui"},
                                                {value: 0, label: "Non"}
                                            ]}
                                            onChange={value => setValue("major_text", parseInt(value))}
                                        >
                                            {data.repealed_text === 1 && (<>
                                                (Texte majeur forcé, le texte est abrogé)
                                            </>)}
                                        </FieldComponent>
                                    </div>
                                    {sheet.is_private && (
                                        <div className="col-md-6 valign-center">
                                            <div className="flex-label">
                                                <span className="label">Fiche privative&nbsp;:</span>
                                                <span><i className="icon-autres-fiche-privative color-vert-apave" aria-hidden="true"></i></span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </ToggableBlocComponent>

                        <section className="bloc">
                            <div>
                                <FieldComponent
                                    label="Communication interne"
                                    type="textarea"
                                    name="internal_comment"
                                    value={data.internal_comment}
                                    className="field h-100px"
                                    onChange={value => setValue("internal_comment", value)}
                                />
                                <FieldComponent
                                    label="Action(s) à réaliser"
                                    type="select"
                                    name="todos"
                                    options={getSheetTodo()}
                                    value={data.todos}
                                    error={errors.todos}
                                    multiple
                                    clearable
                                    onChange={values => setValue("todos", values)}
                                />
                            </div>
                        </section>

                        <ToggableBlocComponent
                            label="Niveau synthèse"
                            id="niveau-synthese"
                            toggled={togglers["niveau-synthese"]}
                            toggle={toggle}
                        >
                            <div className="bg-blanc">
                                <FieldComponent
                                    label="Documentaliste affecté"
                                    type="select"
                                    name="librarian"
                                    className="field small"
                                    value={data.librarian}
                                    error={errors.librarian}
                                    onChange={value=> setValue("librarian", value)}
                                    options={librarians}
                                    clearable
                                />
                                <div className="flex-label">
                                    <span className="label">Etat niveau synthèse&nbsp;:</span>
                                    <div>
                                        <StatusComponent
                                            options={getSheetState(SHEET_STATE_TYPE_SYNTHESIS)}
                                            value={sheet.state_synthesis}
                                            size="large"
                                        />
                                        {!stateSynthesis.force && sheet.id && (<>
                                            <br />
                                            <button
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setStateSynthesis(stateSynthesis => {
                                                        stateSynthesis.force = true;
                                                        return {...stateSynthesis};
                                                    });
                                                }} 
                                                className="btn secondary btn-gris-20"
                                            >
                                                Forcer le statut
                                            </button>
                                        </>)}
                                    </div>
                                </div>
                                {stateSynthesis.force && (
                                    <FieldComponent
                                        label="Nouvel état niveau synthèse"
                                        type="select"
                                        className="field small"
                                        options={getSheetStateActionAvailable(sheet.state_synthesis, SHEET_STATE_TYPE_SYNTHESIS)}
                                        value={stateSynthesis.value}
                                        onChange={value => {
                                            setStateSynthesis(stateSynthesis => {
                                                stateSynthesis.value = value;
                                                stateSynthesis.id = getSheetActionName(value);
                                                return {...stateSynthesis};
                                            });
                                        }}
                                    >
                                        <LoadButton 
                                            loading={saving === stateSynthesis.id} 
                                            label="Forcer"
                                            className="btn btn-error"
                                            name={stateSynthesis.id}
                                            id={stateSynthesis.id}
                                        />
                                    </FieldComponent>
                                )}
                                <FieldComponent
                                    label="Titre"
                                    name="title"
                                    type="textarea"
                                    rte
                                    rtelight
                                    value={data.title}
                                    onChange={value => setValue("title", value)}
                                    error={errors.title}
                                />
                            </div>
                            <div className="bg-gris-10 border-t border-gris-40">
                                <FileComponent
                                    label="Texte réglementaire (PDF)"
                                    extensions={[".pdf"]}
                                    value={data.reglementary_text}
                                    name="reglementary_text"
                                    onChange={value => setValue('reglementary_text', value)}
                                    upload={SheetApi.uploadReglementaryText}
                                    error={errors.reglementary_text}
                                    showSize
                                    maxSize="20MO"
                                />
                                <div className="flex-label">
                                    <label></label>
                                    <a href={`https://www.legifrance.gouv.fr/jorf/id/${sheet.legifrance_id}`} rel="noreferrer" target="_blank">Voir le texte sur www.legifrance.gouv.fr</a>
                                </div>
                            </div>
                            <div className="bg-blanc border-t border-gris-40">
                                <FieldComponent
                                    label="Lien vers la source officielle"
                                    name="legifrance_source"
                                    className="field medium"
                                    value={data.legifrance_source}
                                    error={errors.legifrance_source}
                                    onChange={value => setValue("legifrance_source", value)}
                                />
                                <FieldComponent
                                    label="Numéro NOR"
                                    name="nor"
                                    className="field small"
                                    value={data.nor}
                                    error={errors.nor}
                                    onChange={value => setValue("nor", value)}
                                />
                                <FieldComponent
                                    label="N° dans la source"
                                    name="source_number"
                                    className="field small"
                                    value={data.source_number}
                                    error={errors.source_number}
                                    onChange={value => setValue("source_number", value || "")}
                                />
                                <FieldComponent
                                    label="N° de version du texte"
                                    name="version_number"
                                    className="field small"
                                    value={data.version_number}
                                    error={errors.version_number}
                                    onChange={value => setValue("version_number", value)}
                                />
                                <FieldComponent
                                    label="Emetteur"
                                    type="select"
                                    name="transmitter"
                                    options={transmitters}
                                    value={data.transmitter}
                                    error={errors.transmitter}
                                    clearable
                                    onChange={value => setValue("transmitter", value)}
                                />
                                <FieldComponent
                                    label="Type de texte"
                                    type="select"
                                    name="text_type"
                                    className="field small"
                                    options={textTypes}
                                    value={data.text_type}
                                    error={errors.text_type}
                                    clearable
                                    onChange={value => setValue("text_type", value)}
                                />
                                <FieldComponent
                                    label="Numéro de texte"
                                    name="text_number"
                                    className="field small"
                                    value={data.text_number}
                                    error={errors.text_number}
                                    onChange={value => setValue("text_number", value)}
                                />
                                <FieldComponent
                                    label="Source du texte"
                                    type="select"
                                    name="source"
                                    className="field small"
                                    options={sources}
                                    value={data.source}
                                    error={errors.source}
                                    clearable
                                    onChange={value => setValue("source", value)}
                                />
                                <div className="bg-blanc">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <FieldComponent
                                                label="Texte abrogé"
                                                name="repealed_text"
                                                type="radio"
                                                value={data.repealed_text}
                                                error={errors.repealed_text}
                                                options={[
                                                    {value: 1, label: "Oui"},
                                                    {value: 0, label: "Non"}
                                                ]}
                                                onChange={value => {
                                                        setValue("repealed_text", parseInt(value));
                                                        if (value === 1) {
                                                            setValue("major_text", parseInt(0));
                                                            data.major_text = 0;
                                                        }
                                                    }
                                                }
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <FieldComponent
                                                label="Texte consolidé"
                                                name="consolidated_text"
                                                type="radio"
                                                value={data.consolidated_text}
                                                error={errors.consolidated_text}
                                                options={[
                                                    {value: 1, label: "Oui"},
                                                    {value: 0, label: "Non"}
                                                ]}
                                                onChange={value => setValue("consolidated_text", parseInt(value))}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gris-10">
                                <div className="row">
                                    <div className="col-md-6">
                                        <FieldComponent
                                            label="Date de publication"
                                            type="date"
                                            name="publication_date"
                                            className="field small"
                                            value={data.publication_date}
                                            error={errors.publication_date}
                                            onChange={value => setValue("publication_date", value)}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        {!sheet.id && 
                                            <div className="text-warning">
                                                <strong>ATTENTION :</strong> Les fiches sans date de publication ne remontent pas dans les recherches en cas de filtre sur la date de parution.<br />
                                                Laissez ce champ vide uniquement s'il est impossible à remplir pour le texte concerné.
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="bg-blanc">
                                <div className="row">
                                    <div className="col-md-6">
                                        <FieldComponent
                                            label="Date du texte"
                                            type="date"
                                            name="text_date"
                                            className="field small"
                                            value={data.text_date}
                                            error={errors.text_date}
                                            onChange={value => setValue("text_date", value)}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <FieldComponent
                                            label="Date de mise à jour du texte"
                                            type="date"
                                            name="text_update_date"
                                            className="field small"
                                            value={data.text_update_date}
                                            error={errors.text_update_date}
                                            onChange={value => setValue("text_update_date", value)}
                                        />
                                    </div>
                                    {sheet.id && (
                                        <div className="col-md-6">
                                            <div className="flex-label">
                                                <span className="label">Date de création&nbsp;:</span>
                                                <span>{formatDate(sheet.created_at)}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="bg-blanc">
                                <FieldComponent
                                    label="Codification du texte"
                                    type="select"
                                    name="text_codification"
                                    options={textCodifications}
                                    value={data.text_codification}
                                    error={errors.text_codification}
                                    className="field small"
                                    clearabled
                                    onChange={value => setValue("text_codification", value)}
                                />
                                {sheet.synthesis_updated_at && (<>    
                                    <div className="flex-label">
                                        <span className="label">Auteur dernière modification niveau synthèse&nbsp;:</span>
                                        {sheet.synthesis_updated_by && <span>{sheet.synthesis_updated_by.lastname} {sheet.synthesis_updated_by.firstname}</span>}
                                    </div>
                                    <div className="flex-label">
                                        <span className="label">Date de dernière modification niveau synthèse&nbsp;:</span>
                                        <span>{formatDate(sheet.synthesis_updated_at, true)}</span>
                                    </div>
                                </>)}                                    
                            </div>

                            <div className="bg-gris-10 border-t border-gris-40">
                                <FieldComponent
                                    label="Synthèse"
                                    type="textarea"
                                    rte
                                    name="synthesis"
                                    value={data.synthesis}
                                    error={errors.synthesis}
                                    onChange={value => setValue("synthesis", value)}
                                    className="field h-100px"
                                />
                            </div>
                            <div className="bg-blanc border-t border-gris-40">
                                <FieldComponent
                                    label="Descripteurs"
                                    type="select"
                                    name="descriptors"
                                    options={descriptors}
                                    value={data.descriptors}
                                    error={errors.descriptors}
                                    clearable
                                    multiple
                                    onChange={values => setValue("descriptors", values)}
                                />
                            </div>
                        </ToggableBlocComponent>

                        <ToggableBlocComponent
                            label="Niveau technique"
                            id="niveau-technique"
                            toggled={togglers["niveau-technique"]}
                            toggle={toggle}
                        >
                            <div className="bg-blanc">
                                <FieldComponent
                                    label="Expert affecté"
                                    type="select"
                                    name="expert"
                                    className="field small"
                                    value={data.expert}
                                    error={errors.expert}
                                    options={experts}
                                    onChange={value => setValue("expert", value)}
                                    clearable
                                />
                                {sheet.technical_updated_at && (<>    
                                    <div className="flex-label">
                                        <span className="label">Auteur dernière modification niveau technique&nbsp;:</span>
                                        {sheet.technical_updated_by && <span>{sheet.technical_updated_by.lastname} {sheet.technical_updated_by.firstname}</span>}
                                    </div>
                                    <div className="flex-label">
                                        <span className="label">Date de dernière modification niveau technique&nbsp;:</span>
                                        <span>{formatDate(sheet.technical_updated_at, true)}</span>
                                    </div>
                                </>)}
                                <div className="flex-label">
                                    <span className="label">Etat niveau technique&nbsp;:</span>
                                    <div>
                                        <StatusComponent
                                            options={getSheetState(SHEET_STATE_TYPE_TECHNICAL)}
                                            value={sheet.state_technical}
                                            size="large"
                                        />
                                        {!stateTechnical.force && sheet.id && (<>
                                            <br />
                                            <button
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setStateTechnical(stateTechnical => {
                                                        stateTechnical.force = true;
                                                        return {...stateTechnical};
                                                    });
                                                }} 
                                                className="btn secondary btn-gris-20"
                                            >
                                                Forcer le statut
                                            </button>
                                        </>)}
                                    </div>
                                </div>
                                {stateTechnical.force && (
                                    <FieldComponent
                                        label="Nouvel état niveau technique"
                                        type="select"
                                        className="field small"
                                        options={getSheetStateActionAvailable(sheet.state_technical, SHEET_STATE_TYPE_TECHNICAL)}
                                        value={stateTechnical.value}
                                        onChange={value => {
                                            setStateTechnical(stateTechnical => {
                                                stateTechnical.value = value;
                                                stateTechnical.id = getSheetActionName(value);
                                                return {...stateTechnical};
                                            });
                                        }}
                                    >
                                        <LoadButton 
                                            loading={saving === stateTechnical.id} 
                                            label="Forcer"
                                            className="btn btn-error"
                                            name={stateTechnical.id}
                                            id={stateTechnical.id}
                                        />
                                    </FieldComponent>
                                )}
                            </div>
                            <div className="bg-gris-10 border-t border-gris-40">
                                <FieldComponent
                                    label="Commentaire technique"
                                    type="textarea"
                                    name="technical_comment"
                                    value={data.technical_comment}
                                    error={errors.technical_comment}
                                    rte
                                    className="field h-100px"
                                    onChange={value => setValue("technical_comment", value)}
                                />
                            </div>
                            <div className="bg-gris-10 border-t border-gris-40">
                                <FieldComponent
                                    label="Sanction"
                                    type="textarea"
                                    name="sanction"
                                    value={data.sanction}
                                    error={errors.sanction}
                                    rte
                                    className="field h-100px"
                                    onChange={value => setValue("sanction", value)}
                                />
                            </div>
                            <SheetDeadline
                                value={data.deadlines}
                                error={errors.deadlines}
                                onChange={value => setValue("deadlines", value)}
                            />
                            <div className="bg-blanc border-t border-gris-40">
                                <FieldComponent
                                    label="Impact(s) du texte"
                                    name="impacts"
                                    className="field"
                                    type="select"
                                    multiple
                                    value={data.text_impacts}
                                    error={errors.text_impacts}
                                    options={textImpacts}
                                    onChange={values => setValue("text_impacts", values)}
                                />
                                <div className="offset-label">
                                    <div className="row">
                                        <FieldComponent
                                            label="Degrés d'impact corporel"
                                            type="radio"
                                            name="corporal_impact_degree"
                                            options={getDegree()}
                                            value={data.corporal_impact_degree}
                                            error={errors.corporal_impact_degree}
                                            wrapper="degree"
                                            onChange={value => setValue("corporal_impact_degree", parseInt(value))}
                                        />
                                        <FieldComponent
                                            label="Degrés d'impact médiatique"
                                            type="radio"
                                            name="media_impact_degree"
                                            options={getDegree()}
                                            value={data.media_impact_degree}
                                            error={errors.media_impact_degree}
                                            wrapper="degree"
                                            onChange={value => setValue("media_impact_degree", parseInt(value))}
                                        />
                                        <FieldComponent
                                            label="Degrés d'impact juridique"
                                            type="radio"
                                            name="legal_impact_degree"
                                            options={getDegree()}
                                            value={data.legal_impact_degree}
                                            error={errors.legal_impact_degree}
                                            wrapper="degree"
                                            onChange={value => setValue("legal_impact_degree", parseInt(value))}
                                        />
                                        <FieldComponent
                                            label="Degrés d'impact financier"
                                            type="radio"
                                            name="financial_impact_degree"
                                            options={getDegree()}
                                            value={data.financial_impact_degree}
                                            error={errors.financial_impact_degree}
                                            wrapper="degree"
                                            onChange={value => setValue("financial_impact_degree", parseInt(value))}
                                        />
                                    </div>
                                </div>
                                <FieldComponent
                                    label="Acteur(s) concerné(s)"
                                    name="involved_actors"
                                    type="select"
                                    multiple
                                    value={data.involved_actors}
                                    error={errors.involved_actors}
                                    options={involvedActors}
                                    onChange={values => setValue("involved_actors", values)}
                                />
                                <FieldComponent
                                    label="Fonction(s) impactée(s)"
                                    name="involved_functions"
                                    type="select"
                                    multiple
                                    value={data.involved_functions}
                                    error={errors.involved_functions}
                                    options={involvedFunctions}
                                    onChange={values => setValue("involved_functions", values)}
                                />
                            </div>
                            <div className="bg-gris-10 border-t border-gris-40">
                                <FieldComponent
                                    label="Commentaire consultant type"
                                    type="textarea"
                                    rte
                                    name="consultant_comment"
                                    value={data.consultant_comment}
                                    error={errors.consultant_comment}
                                    onChange={value => setValue("consultant_comment", value)}
                                />
                            </div>
                        </ToggableBlocComponent>

                        <ToggableBlocComponent
                            label="Domaines / sous-domaines / thèmes"
                            id="domaines"
                            toggled={togglers["domaines"]}
                            toggle={toggle}
                        >
                            <div className="bg-blanc">                                
                                <FieldComponent 
                                    type="dropdown-tree-select"
                                    label="Domaines / Sous domaines / Thèmes"
                                    name="categories"
                                    data={domainTree}
                                    mode="hierarchical"
                                    value={data.categories}
                                    error={errors.categories}
                                    onChange={categoriesChange}
                                />
                                <FieldComponent
                                    label="Domaine principal"
                                    name="main_domain"
                                    className="field small"
                                    value={data.main_domain}
                                    error={errors.main_domain}
                                    type="select"
                                    options={availableDomains}
                                    onChange={value => setData({...data, ...{main_domain: value, main_subdomain: null, main_theme: null}})}
                                    clearable
                                />
                                <FieldComponent
                                    label="Sous-domaine principal"
                                    name="main_subdomain"
                                    className="field small"
                                    value={data.main_subdomain}
                                    error={errors.main_subdomain}
                                    type="select"
                                    options={availableSubdomains}
                                    onChange={value => setData({...data, ...{main_subdomain: value, main_theme: null}})}
                                    clearable
                                />
                                <FieldComponent
                                    label="Thème principal"
                                    name="main_theme"
                                    className="field small"
                                    value={data.main_theme}
                                    error={errors.main_theme}
                                    type="select"
                                    options={availableThemes}
                                    onChange={value => setValue("main_theme", value)}
                                    clearable
                                />
                            </div>
                        </ToggableBlocComponent>

                        <ToggableBlocComponent
                            label="Relations"
                            id="relations"
                            toggled={togglers["relations"]}
                            toggle={toggle} 
                        >
                            <div className="bg-blanc">
                                <LinkedSheetPicker 
                                    values={data.linked_sheet_importants}
                                    reverseValues={data.linked_sheet_others}
                                    label="Fiche(s) liée(s), lien(s) important(s)"
                                    onChange={(values, reverseValues) => {
                                        setValue({
                                            "linked_sheet_importants": values,
                                            "linked_sheet_others": reverseValues,
                                        });
                                    }}
                                    moveArrow="down"
                                    sourceSheet={sheet.id}
                                />
                                <LinkedSheetPicker
                                    values={data.linked_sheet_others}
                                    reverseValues={data.linked_sheet_importants}
                                    label="Fiche(s) liée(s), autre(s) lien(s)"
                                    onChange={(values, reverseValues) => {
                                        setValue({
                                            "linked_sheet_importants": reverseValues,
                                            "linked_sheet_others": values,
                                        });
                                    }}
                                    moveArrow="up"
                                    sourceSheet={sheet.id}
                                />
                                <WeblinkPicker
                                    label="Liens internet"
                                    values={data.weblinks}
                                    onChange={values => setValue("weblinks", values)}
                                />
                                <div className="offset-label flex-label">
                                    <Link className="btn btn-primary icon h30 uppercase" to="/sheets" target="_blank">
                                        <i className="icon-documentaire-recherche" aria-hidden="true"></i>
                                        &nbsp;Ouvrir la recherche de fiche dans un nouvel onglet
                                    </Link>
                                </div>
                                <FileComponent
                                    name="attachments"
                                    label="Fichier(s) lié(s)"
                                    upload={SheetApi.uploadAttachments}
                                    value={data.attachments}
                                    error={errors.attachments}
                                    extensions={['.pdf', '.doc', '.xls', '.txt']}
                                    showDate
                                    showSize
                                    multiple
                                    onChange={values => setValue("attachments", values)}
                                    maxSize="5MO"
                                />                                
                            </div>
                        </ToggableBlocComponent>

                        <ToggableBlocComponent
                            label="Dépêches"
                            id="depeches"
                            toggled={togglers["depeches"]}
                            toggle={toggle}
                        >
                            <div className="bg-blanc">
                                <FieldComponent
                                    label="Dépêche associée"
                                    name="dispatch"
                                    value={data.dispatch}
                                    error={errors.dispatch}
                                    disabled={true}
                                    className="field small"
                                    onChange={value => setValue("dispatch", value)}
                                />
                                <FieldComponent
                                    label="Ajouté à la dépêche"
                                    name="add_to_dispatch"
                                    type="radio"
                                    value={data.add_to_dispatch}
                                    error={errors.add_to_dispatch}
                                    disabled={data.dispatch === "" || _.isNil(data.dispatch) ? false : true}
                                    options={[
                                        {value: 1, label: "Oui"},
                                        {value: 0, label: "Non"}
                                    ]}
                                    onChange={value => setValue("add_to_dispatch", parseInt(value))}
                                />
                                <FileComponent
                                    name="dispatch_image"
                                    label="Image pour dépêche"
                                    value={data.dispatch_image}
                                    error={errors.dispatch_image}
                                    upload={SheetApi.uploadDispatchImage}
                                    showSize
                                    extensions={['.jpg', '.jpeg', '.png', '.gif']}
                                    onChange={value => setValue("dispatch_image", value)}
                                />
                            </div>
                        </ToggableBlocComponent>

                        <ToggableBlocComponent
                            label="Tâches clients"
                            id="taches"
                            toggled={togglers["taches"]}
                            toggle={toggle}
                        >
                            <div className="bg-blanc">
                                <FieldComponent
                                    label="Contrôle à effectuer"
                                    type="textarea"
                                    name="control_todo"
                                    className="field h-100px"
                                    value={data.control_todo}
                                    error={errors.control_todo}
                                    onChange={value => setValue("control_todo", value)}
                                />
                                <FieldComponent
                                    label="Document à faire"
                                    type="textarea"
                                    name="document_todo"
                                    className="field h-100px"
                                    value={data.document_todo}
                                    error={errors.document_todo}
                                    onChange={value => setValue("document_todo", value)}
                                />
                                <FieldComponent
                                    label="Périodicité"
                                    type="textarea"
                                    name="periodicity"
                                    className="field h-100px"
                                    value={data.periodicity}
                                    error={errors.periodicity}
                                    onChange={value => setValue("periodicity", value)}
                                />
                            </div>
                        </ToggableBlocComponent>

                        <section className="row">
                            <div className="col-md-3">
                                <button id="back-sheet-list" type="button" onClick={goBack} className="btn btn-bleu-4">Retour à la liste</button>
                                <Link id="goto-sheet-detail" className="btn btn-primary" to={`/sheets/${sheet.id}/preview`}>Consulter la fiche</Link>
                            </div>
                            <div className="col-md-9 text-right">
                                {sheet.id && <Link
                                    id="link-to-history"
                                    to={`/history/sheets/${sheet.id}`}
                                    className="btn btn-primary"
                                    target="_blank"
                                >
                                    <i className="icon-boutons-voir-historique" aria-hidden="true"></i>
                                    Voir l'historique
                                </Link>}
                                {sheet.id && canPerform('requirement:list') && 
                                    <Link
                                        id="goto-requirements"
                                        to={`/sheets/${sheet.id}/requirements/`}
                                        className="btn btn-primary"
                                        target="_blank"
                                    >
                                        <i className="icon-boutons-lien-vers" aria-hidden="true"></i>
                                        Accéder aux exigences
                                    </Link>
                                }
                                {sheet.status !== SHEET_ARCHIVED && (
                                    <LoadButton 
                                        loading={saving === "save-sheet"} 
                                        label={sheet.id ? "Enregistrer" : "Créer la fiche"}
                                        name="save-sheet"
                                        id="save-sheet"
                                    />
                                )}
                                {sheet.id && (<>
                                    {sheet.status === SHEET_TO_RULE && (
                                        <LoadButton 
                                            loading={saving === "save-and-retain-sheet"} 
                                            label="Enregistrer et retenir la fiche"
                                            name="save-and-retain-sheet"
                                            id="save-and-retain-sheet"
                                        />
                                    )}
                                    {sheet.status === SHEET_ARCHIVED && (
                                        <LoadButton 
                                            loading={saving === "save-and-reactivate-sheet"} 
                                            label="Enregistrer et réactiver la fiche"
                                            name="save-and-reactivate-sheet"
                                            id="save-and-reactivate-sheet"
                                        />
                                    )}
                                    {sheet.status !== SHEET_ARCHIVED && (
                                        <LoadButton 
                                            loading={saving === "save-and-archive-sheet"}
                                            label="Archiver"
                                            name="save-and-archive-sheet"
                                            id="save-and-archive-sheet"
                                        />
                                    )}
                                    {sheet.state_synthesis === SHEET_STATE_TODO  && sheet.status === SHEET_TO_DO && (
                                        <LoadButton 
                                            loading={saving === "save-and-validate-synthesis-sheet"} 
                                            label="Enregistrer et valider le niveau synthèse"
                                            name="save-and-validate-synthesis-sheet"
                                            id="save-and-validate-synthesis-sheet"
                                        />
                                    )}
                                    {sheet.state_technical === SHEET_STATE_TODO && sheet.status === SHEET_TO_DO && (
                                        <LoadButton 
                                            loading={saving === "save-and-validate-technical-sheet"} 
                                            label="Enregistrer et valider le niveau technique"
                                            name="save-and-validate-technical-sheet"
                                            id="save-and-validate-technical-sheet"
                                        />
                                    )}
                                    {sheet.state_technical === SHEET_STATE_TODECIDE && sheet.status !== SHEET_ARCHIVED && (
                                        <LoadButton
                                            loading={saving === "save-and-technical-unnecessary-sheet"} 
                                            label="Niveau technique non nécessaire"
                                            name="save-and-technical-unnecessary-sheet"
                                            id="save-and-technical-unnecessary-sheet"
                                        />
                                    )}
                                </>)}
                            </div>
                        </section>
                    </form>
                </section>
            </section>
            <section className="row grid">
                {sheet.id && canPerform('affectations:list') &&
                    <button
                        id="detail-affectations"
                        onClick={() => openingAffectations(true)}
                        className="btn btn-primary"
                    >
                        Voir les affectations
                    </button>
                }
                <Can perform="affectations:list" yes={() => <>
                    <Modal
                        isOpen={affectationsOpened}
                        onRequestClose={() => openingAffectations(false)}
                        style={mediumModalCustomStyles}>
                        <Affectations sheet={sheet} onRequestClose={() => openingAffectations(false)} />
                    </Modal>
                </>} />
            </section>
        </DefaultLayout>
    )
}