
import BarLoader from "react-spinners/BarLoader";
import DefaultLayout from "layouts/DefaultLayout";

export default function PageLoadingComponent(props) {

    const { label, layout } = props;

    if (layout) {
        return (
            <DefaultLayout>
                <section className="bloc">
                    <h1>{label}</h1>
                    <div className="text-center">
                        <BarLoader width={300} color="#5bad27" css="display:block;margin:10px auto;" />
                        <span>Chargement en cours...</span>
                    </div>
                </section>
            </DefaultLayout>
        );
    } else {
        return (
            <>
                <section className="bloc">
                    <h1>{label}</h1>
                    <div className="text-center">
                        <BarLoader width={300} color="#5bad27" css="display:block;margin:10px auto;" />
                        <span>Chargement en cours...</span>
                    </div>
                </section>
            </>
        );
    }   
}


PageLoadingComponent.defaultProps = {
    layout: true
};

