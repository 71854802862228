import { ACTION_TODECIDE_TECHNICAL, ACTION_TODO_SYNTHESIS, ACTION_TODO_TECHNICAL, SHEET_ACTION_TECHNICAL_UNNECESSARY, SHEET_ACTION_VALIDATE_SYNTHESIS, SHEET_ACTION_VALIDATE_TECHNICAL } from "shared/data/SheetAction";

export const SHEET_STATE_TODECIDE = 1;
export const SHEET_STATE_UNNECESSARY = 2; 
export const SHEET_STATE_TODO = 3;
export const SHEET_STATE_VALIDATED = 4;
export const SHEET_STATE = {
    [SHEET_STATE_TODECIDE]: "A décider",
    [SHEET_STATE_UNNECESSARY]: "Non nécessaire",
    [SHEET_STATE_TODO]: "A faire",
    [SHEET_STATE_VALIDATED]: "Validé",
}
export const SHEET_STATE_SMALL = {
    [SHEET_STATE_TODECIDE]: "D",
    [SHEET_STATE_UNNECESSARY]: "N",
    [SHEET_STATE_TODO]: "F",
    [SHEET_STATE_VALIDATED]: "V",
}
export const SHEET_STATE_COLOR = {
    [SHEET_STATE_TODECIDE]: "error",
    [SHEET_STATE_UNNECESSARY]: "new",
    [SHEET_STATE_TODO]: "alert",
    [SHEET_STATE_VALIDATED]: "valid",
}

export const SHEET_STATE_TYPE_SYNTHESIS = 1;
export const SHEET_STATE_TYPE_TECHNICAL = 2;
export const SHEET_STATE_ACTION = {
    [SHEET_STATE_TYPE_SYNTHESIS]: {
        [SHEET_STATE_TODO]: ACTION_TODO_SYNTHESIS,
        [SHEET_STATE_VALIDATED]: SHEET_ACTION_VALIDATE_SYNTHESIS,
    },
    [SHEET_STATE_TYPE_TECHNICAL]: {
        [SHEET_STATE_TODECIDE]: ACTION_TODECIDE_TECHNICAL,
        [SHEET_STATE_UNNECESSARY]: SHEET_ACTION_TECHNICAL_UNNECESSARY,
        [SHEET_STATE_TODO]: ACTION_TODO_TECHNICAL,
        [SHEET_STATE_VALIDATED]: SHEET_ACTION_VALIDATE_TECHNICAL,
    },
}
export const SHEET_STATE_TYPE = {
    [SHEET_STATE_TYPE_SYNTHESIS]: [SHEET_STATE_TODO, SHEET_STATE_VALIDATED],
    [SHEET_STATE_TYPE_TECHNICAL]: [SHEET_STATE_TODECIDE, SHEET_STATE_UNNECESSARY, SHEET_STATE_TODO, SHEET_STATE_VALIDATED],
}

export function getSheetState(type = SHEET_STATE_TYPE_SYNTHESIS) {
    return SHEET_STATE_TYPE[type].map(sheetState => {
        return {
            value: parseInt(sheetState), 
            label: SHEET_STATE[sheetState],
            color: SHEET_STATE_COLOR[sheetState],
        };
    });
}


export function getSheetStateSmall(type = SHEET_STATE_TYPE_SYNTHESIS) {
    return SHEET_STATE_TYPE[type].map(sheetState => {
        return {
            value: parseInt(sheetState), 
            label: SHEET_STATE_SMALL[sheetState],
            title: SHEET_STATE[sheetState],
            color: SHEET_STATE_COLOR[sheetState],
        };
    });
}

export function getSheetStateActionAvailable(state, type = SHEET_STATE_TYPE_SYNTHESIS) {
    return SHEET_STATE_TYPE[type].filter(sheetState => sheetState !== state).map(sheetState => {
        return {
            value: parseInt(SHEET_STATE_ACTION[type][sheetState]),
            label: SHEET_STATE[sheetState],
        }
    });
}

export function getSheetStateLabel(state) {
    return SHEET_STATE[state];
}

export function getSheetStateColor(state) {
    return SHEET_STATE_COLOR[state];
}