import React from "react";
import { getDegree } from 'shared/data/Degree';

export default function DegreeReadOnlyComponent(props) {

    return <div className="form">
        <fieldset className="degres">
            {getDegree().map((option, index) => (<React.Fragment key={index}>
                <input type="radio"  checked={option.value === props.value} disabled={true} readOnly={true} />
                <label className={option.labelClassName}>
                    <span className="sr-only">{option.label}</span>
                </label>
            </React.Fragment>))}
        </fieldset>
    </div>
}