import React from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import DropdownTreeSelect from "react-dropdown-tree-select";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'aio-ckeditor';
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import _ from "lodash";
import { formatDate } from '../services/Utils';
import ChecksingleReadOnlyComponent from './ChecksingleReadOnlyComponent';
import HtmlComponent from './HtmlComponent';

registerLocale("fr", fr);

export default function FieldComponent(props) {

    const { type, error, rte, multiple } = props;
    let { className, wrap, wrapper } = props;
    let values;
    let labelClassName = "";
    const id = props.prefixId + props.name;
    let errorClassName = "help-block color-danger";
    const maxSize = props.maxSize || "2MO";
    const extensions = props.extensions;

    switch(type) {
        case 'hidden':
            wrap = false;
            break;
        case 'textarea':
        case 'dropdown-tree-select':
        case 'checkboxes':
            labelClassName = "at-top";
            errorClassName = "help-block color-danger at-top";
            break;
        case 'date':
            if (className !== "") {
                className = className + " xsmall";
            }
            break;
        case 'select':
        case 'simple-select':
            values = [];
            if (props.options) {
                Object.entries(props.options).forEach(([index, option]) => {
                    if (!props.multiple && option.value === props.value) {
                        values.push(option);
                    }
                    if (props.multiple && props.value.indexOf(option.value) > -1) {
                        values.push(option);
                    }
                });
            }
            if(type === "select") {
                className = "react-select-container " + className;
            }
            break;
        case 'async-select':
            values = [];
            if (props.defaultOptions) {
                Object.entries(props.defaultOptions).forEach(([index, option]) => {
                    if (!props.multiple && option.value === props.value) {
                        values.push(option);
                    }
                    if (props.multiple && _.isArray(props.value) && props.value.indexOf(option.value) > -1) {
                        values.push(option);
                    }
                });
            }
            className = "react-select-container " + className;
            labelClassName = "at-top";
            errorClassName = "help-block color-danger at-top";
            break;
        default:
    }

    if (error) {
        className = className + " border-danger";
        labelClassName = labelClassName + " color-danger";
    }

    function preOnChange(event, data) {
        if(props.onChange) {
            props.onChange(
                formatValue(event, data),
                event
            );
        }
    }

    function formatValue(event, data) {
        switch(type) {
            case 'dropdown-tree-select':
                switch (props.mode) {
                    case 'hierarchical': return data.map(i => i.value);
                    case 'multiSelect': return data.map(i => i.value);
                    case 'radioSelect': return data.length > 0 ? data.shift().value : null;
                    default: return event.value;
                }
            case 'checkbox':
            case 'checksingle':
                return event.target.checked;
            case 'file':
                if(multiple === true) {
                    return event.target.files;
                } else {
                    return event.target.files[0];
                }
            case 'textarea':
                if(rte === true) {
                    return data.getData();
                } else {
                    return event.target.value;
                }
            case 'date':
            case 'datetime':
                if (!event) {
                    return null;
                }
                const offset = -(event.getTimezoneOffset());
                const offsetDate = new Date();
                offsetDate.setTime(event.getTime() + offset * 60000);
                return offsetDate;
            case 'select':
            case 'async-select':
                if(multiple === true) {
                    let values = [];
                    if (event) {
                        Object.entries(event).forEach(([index, option]) => {
                            values.push(option.value);
                        });
                    }
                    return values;
                } else {
                    return event ? event.value : null;
                }
            case 'checkboxes':
                const value = parseInt(event.target.value);
                let values = [...props.value];
                if (values.includes(value)) {
                    values = values.filter(item => item !== value);
                } else {
                    values.push(value);
                }
                return values;
            default:
                return event.target.value || event.value || null;
        }
    }

    function renderInput() {
        switch(type) {
            case 'checkboxes':
                return (<>
                    {props.options.map((option, index) => (<div key={index}>
                        <input
                            type="checkbox"
                            name={props.name}
                            id={`${props.name}-${index}`}
                            value={option.value}
                            onChange={preOnChange}
                            selected={props.value.includes(option.value)}
                            checked={props.value.includes(option.value)}
                            disabled={props.disabled || option.disabled}
                            readOnly={props.readOnly}
                        />
                        <label id={`${props.name}-${index}-label`} className={`bg-checked-${option.color}`} htmlFor={`${props.name}-${index}`}>
                            {option.labelSrOnly ?
                                <span className="sr-only">{option.label}</span>
                                :
                                option.label
                            }
                        </label>
                    </div>))}
                </>);
            case 'checksingle':
                return (
                    <input
                        type="checkbox"
                        className={className}
                        name={props.name}
                        id={id}
                        onChange={preOnChange}
                        value={props.value}
                        checked={props.checked}
                        disabled={props.disabled}
                        readOnly={props.readOnly}
                    />
                );
            case 'status':
            case 'buttons':
                return (<>
                    {props.options.map((option, index) => (<React.Fragment key={index}>
                        <input
                            type="radio" 
                            name={props.name}
                            id={`${props.name}-${index}`}
                            value={option.value}
                            onChange={preOnChange} 
                            selected={option.value === props.value}
                            checked={option.value === props.value}
                            disabled={props.disabled || option.disabled}
                            readOnly={props.readOnly}
                        />
                        <label id={`${props.name}-${index}-label`} className={`bg-checked-${option.color}`} htmlFor={`${props.name}-${index}`}>
                            {option.labelSrOnly ?
                                <span className="sr-only">{option.label}</span>
                                :
                                option.label
                            }
                        </label>
                    </React.Fragment>))}
                </>)
            case 'radio':
                return (<>
                    {props.options.map((option, index) => (<React.Fragment key={index}>
                        <input
                            type="radio" 
                            name={props.name}
                            id={`${props.name}-${index}`}
                            value={option.value}
                            onChange={preOnChange} 
                            selected={option.value === props.value}
                            checked={option.value === props.value}
                            disabled={props.disabled}
                            readOnly={props.readOnly}
                        />
                        <label id={`${props.name}-${index}-label`} className={option.labelClassName} htmlFor={`${props.name}-${index}`}>
                            {option.labelSrOnly ?
                                <span className="sr-only">{option.label}</span>
                                :
                                option.label
                            }
                        </label>
                    </React.Fragment>))}
                </>)
            case 'textarea':
                if (rte === true) {
                    return (
                        <div style={{flex: 1}} className={error ? "border border-error": ""}>
                            {(props.rtelight === null || props.rtelight === false) && <CKEditor
                                editor={Editor}
                                config={{
                                    toolbar: {
                                        items: [
                                            'heading',
                                            '|',
                                            'alignment',
                                            '|',
                                            'bold',
                                            'italic',
                                            'underline',
                                            'strikethrough',
                                            'subscript',
                                            'superscript',
                                            '|',
                                            'fontColor',
                                            'fontBackgroundColor',
                                            'fontSize',
                                            'highlight',
                                            '|',
                                            'bulletedList',
                                            'numberedList',
                                            '|',
                                            'outdent',
                                            'indent',
                                            '|',
                                            'link',
                                            '|',
                                            'horizontalLine',
                                            'blockQuote',
                                            'insertTable',
                                            'specialCharacters',                                            
                                            '|',
                                            'undo',
                                            'redo'
                                        ]
                                    },
                                    language: 'fr',
                                    table: {
                                        contentToolbar: [
                                            'tableColumn',
                                            'tableRow',
                                            'mergeTableCells',
                                            'tableCellProperties',
                                            'tableProperties'
                                        ]
                                    }
                                }}
                                data={props.value || ""}
                                name={props.name}
                                onChange={preOnChange}
                            />}
                            {props.rtelight !== null && props.rtelight !== false &&<CKEditor
                                editor={Editor}
                                config={{
                                    toolbar: {
                                        items: [
                                            'bold',
                                            'italic',
                                            'underline',
                                            'strikethrough',
                                            'subscript',
                                            'superscript',
                                            '|',
                                            'fontColor',
                                            'fontBackgroundColor',
                                            '|',
                                            'specialCharacters',                                            
                                            '|',
                                            'undo',
                                            'redo'
                                        ]
                                    },
                                    language: 'fr',
                                    table: {
                                        contentToolbar: [
                                            'tableColumn',
                                            'tableRow',
                                            'mergeTableCells',
                                            'tableCellProperties',
                                            'tableProperties'
                                        ]
                                    }
                                }}
                                data={props.value || ""}
                                name={props.name}
                                onChange={preOnChange}
                            />}
                        </div>
                    );
                } else {
                    return (
                        <textarea
                            className={className}
                            name={props.name}
                            id={id}
                            onChange={preOnChange}
                            value={props.value || ''}
                            //required={props.required}
                            pattern={props.pattern}
                            disabled={props.disabled}
                        />
                    );
                }
            case 'simple-select':
                return (
                    <select name={props.name} className={className} onChange={preOnChange}>
                        {props.blankLabel && <option defaultValue={props.value === null || props.value === ""}></option>}
                        {props.options.map((option) => <option key={option.value} defaultValue={props.value === option.value} value={option.value}>{option.label}</option>)}
                    </select>
                );
            case 'select':
                return (
                    <Select
                        noOptionsMessage={() => <></>}
                        className={className}
                        classNamePrefix="react-select"
                        placeholder="Sélectionnez..."
                        value={values}
                        name={props.name}
                        id={id}
                        onChange={preOnChange}
                        defaultValue={props.value}
                        //required={props.required}
                        options={props.options}
                        isMulti={props.multiple}
                        isClearable={props.clearable}
                        isDisabled={props.disabled}
                    />
                );
            case 'async-select':
                return (
                    <AsyncSelect
                        noOptionsMessage={() => <></>}
                        className={className}
                        classNamePrefix="react-select"
                        placeholder=""
                        name={props.name}
                        id={id}
                        value={values}
                        onChange={preOnChange}
                        defaultValue={values}
                        //required={props.required}
                        defaultOptions={props.defaultOptions}
                        loadOptions={props.loadOptions}
                        cacheOptions={props.cacheOptions || true}
                        isMulti={props.multiple}
                        isClearable={props.clearable}
                        isDisabled={props.disabled}
                    />
                );
            case 'dropdown-tree-select':
                const mapper = (item) => {
                    item.level = item.parent ? item.parent.level + 1 : 1;

                    if (props.value && props.updateMapperValues) {
                        if (props.mode === "multiSelect") {
                            item.checked = (item.force_checked ? true : false) || props.value.includes(item.value) || (item.parent && item.parent.checked === true);
                        } else if (props.mode === "simpleSelect") {
                            item.checked = props.value === item.value;
                        } else if (props.mode === "radioSelect") {
                            item.checked = props.value === item.value;
                        } else {
                            item.checked = props.value.includes(item.value);
                        }
                    }
                    item.tagLabel = item.parent ? item.parent.tagLabel + " / " + item.label : item.label;
                    if (props.mode === "multiSelect") {
                        item.disabled = item.disabled || (item.parent ? item.parent.checked === true || item.parent.disabled === true : false);
                    }
                    if (props.mode === "radioSelect" && props.enableFromLevel) {
                        if (item.disabled || item.level < props.enableFromLevel) {
                            item.disabled = true;
                        } else {
                            item.disabled = false;
                        }
                    }

                    if (item.children) {
                        item.children.forEach(child => {
                            child.parent = {
                                checked: item.checked,
                                disabled: item.disabled,
                                tagLabel: item.tagLabel,
                                value: item.value,
                                label: item.label,
                                level: item.level
                            };
                        });
                        item.children = item.children.map(mapper);
                        item.children.forEach(child => {
                            item.expanded = child.checked || child.expanded || item.expanded;
                        });
                    }
                    return item;
                };

                return (                
                    <DropdownTreeSelect
                        texts={{
                            placeholder: "Sélectionnez..."
                        }}
                        className={className}
                        name={props.name}
                        id={id}
                        onChange={preOnChange}
                        data={props.useMapper ? props.data.map(mapper) : props.data}
                        mode={props.mode}
                        disabled={props.disabled}
                        showDropdown={props.showDropdown ? props.showDropdown : false}
                    />
                );
            case 'file':
                return (<>
                    <input
                        type={props.type}
                        className={className}
                        name={props.name}
                        id={id}
                        disabled={props.disabled}
                        onFocus={props.onFocus}
                        onChange={preOnChange}
                        readOnly={props.readOnly}
                        onBlur={props.onBlur}
                        //required={props.required}
                        pattern={props.pattern}
                        multiple={props.multiple}
                        accept={props.accept}
                        checked={props.checked}
                    />
                    {props.showRequirements && (
                        <div style={{'color': 'grey', 'fontSize': 10, 'flex': '0 0 100%', 'paddingLeft': 240, 'marginBottom': 12}}>
                            <strong>Taille maximale par fichier :</strong> {maxSize}
                            {extensions && (<>, <strong>extensions autorisées :</strong> {extensions.join(', ').replaceAll('.', '')}</>)}
                        </div>
                    )}
                </>);
            case 'date':
            case 'datetime':
                return (<>
                    <DatePicker
                        id={id}
                        locale="fr"
                        onChange={preOnChange}
                        className={className}
                        selected={props.value}
                        showTimeSelect={type === "datetime"}
                        placeholderText={type === "date" ? "jj/mm/aaaa" : "jj/mm/aaaa hh:mm"}
                        dateFormat={type === "date" ? "dd/MM/yyyy" : "dd/MM/yyyy H:I"}
                        disabled={props.disabled}
                        startDate={props.startDate}
                        endDate={props.endDate}
                        selectsStart={props.selectsStart}
                        selectsEnd={props.selectsEnd}
                        openToDate={new Date()}
                    />
                </>);
            case 'number':
                return (
                    <input
                        type={props.type}
                        className={className}
                        name={props.name}
                        id={id}
                        disabled={props.disabled}
                        onFocus={props.onFocus}
                        onChange={preOnChange}
                        onKeyDown={(e) => {
                            if (!e.key.match(/[0-9]/) && e.key !== "Backspace") {
                                e.preventDefault();
                            }
                        }}
                        readOnly={props.readOnly}
                        onBlur={props.onBlur}
                        value={props.value || ""}
                        //required={props.required}
                        pattern={props.pattern}
                        multiple={props.multiple}
                        checked={props.checked}
                        maxLength={props.maxLength}
                    />
                );
            default:
                return (
                    <input
                        type={props.type}
                        className={className}
                        name={props.name}
                        id={id}
                        disabled={props.disabled}
                        onFocus={props.onFocus}
                        onChange={preOnChange}
                        readOnly={props.readOnly}
                        onBlur={props.onBlur}
                        value={props.value || ""}
                        //required={props.required}
                        pattern={props.pattern}
                        multiple={props.multiple}
                        checked={props.checked}
                        maxLength={props.maxLength}
                    />
                );
        }
    }

    function renderChildren() {
        return props.children;
    }

    function renderErrors() {
        if(props.error) {
            return (
                <span id={`${id}-error`} className={errorClassName}>
                    &nbsp;{props.error}
                </span>
            );
        }
    }

    function renderValue() {
        if (props.valueRender) {
            return props.valueRender(props.value, props.options);
        } else if (props.options && props.options.length > 0) {
            const option = _.find(props.options, {'value': parseInt(props.value)});
            if (option) {
                return option.label;
            } else {
                return "";
            }
        } else if (props.value instanceof Date) {
            return formatDate(props.value);
        } else if (props.checked !== undefined) {
            return <ChecksingleReadOnlyComponent value={props.checked} />
        } else if (props.rte) {
            return <div style={{flex: 1, marginTop: "11px"}}><HtmlComponent>{props.value}</HtmlComponent></div>
        }

        return props.value;
    }

    function renderLabel(suffix = null) {
        let label = props.checksingleLabel || props.label;
        if (props.preview) {
            label = props.label;
        }
        if (props.required && !props.preview) {
            label = label + " *";
        }
        return <label title={props.title ?? ""} htmlFor={id} className={labelClassName} id={`${id}-label`}>{label} {suffix}</label>;        
    }

    function renderWrapper() {
        if(!wrapper) {
            wrapper = type;
        }

        if(_.isFunction(wrapper)) {
            return wrapper([props.label, renderInput(), renderErrors(), renderChildren(), renderLabel()]);
        }

        switch(wrapper) {
            case 'checkboxes':
                return (
                    <fieldset>
                        <legend title={props.title ?? ""} className={labelClassName}>{props.label} : </legend>
                        <div>
                            {renderInput()}
                            {renderErrors()}
                        </div>
                    </fieldset>
                );
            case 'status':
            case 'buttons':
                return (
                    <fieldset className={props.wrapButtonsClassName}>
                        <legend title={props.title ?? ""} className={labelClassName}>{props.label} : </legend>
                        <div className="toggle-group">
                            {renderInput()}
                            {props.checksingleLabel && renderLabel()}
                            {renderChildren()}
                            {renderErrors()}
                        </div>
                    </fieldset>
                );
            case 'fieldset-div':
                return (
                    <fieldset className={className}>
                        <div>
                            {renderInput()}
                            {renderLabel()}                      
                        </div>
                    </fieldset>
                );
            case 'checksingle':
            case 'radio':
                return (
                    <fieldset>
                        <legend title={props.title ?? ""}>{props.label} : </legend>
                        <div>
                            {renderInput()}
                            {props.checksingleLabel && renderLabel()}
                            {renderChildren()}
                            {renderErrors()}
                        </div>
                    </fieldset>
                );
            case 'degree':
                return (
                    <fieldset className="col-md-6 degres">
                        <legend title={props.title ?? ""} className={labelClassName}>{props.label} : </legend>
                        {renderInput()}
                        {props.checksingleLabel && renderLabel()}
                        {renderChildren()}
                        {renderErrors()}
                    </fieldset>
                );
            case 'password':
                function togglePasswordDisplay(id) {
                    if (document.getElementById(id).type === "password") {
                        document.getElementById(id).type = "text";
                    } else {
                        document.getElementById(id).type = "password";
                    }
        
                    document.querySelector("#" + id + " + button i").classList.toggle("icon-general-carat");
                    document.querySelector("#" + id + " + button i").classList.toggle("icon-actions-consulter-fiche");
                }

                return (
                    <div className="flex-label">
                        <label className={labelClassName} htmlFor={id} id={`${id}-label`}>{props.label} : </label>
                        <span className="field-wrap">
                            {renderInput()}
                            <button
                                type="button"
                                title="Afficher/cacher le mot de passe"
                                onClick={e => togglePasswordDisplay(id)}
                            >
                                <i className="icon-actions-consulter-fiche" aria-hidden="true"></i>
                            </button>
                        </span>
                        {renderChildren()}
                        {renderErrors()}
                    </div>
                )
            case 'text-add':
                return (
                    <div className="flex-label">
                        <span className="label" id={`${id}-label`}>{props.label}</span>
                        {renderInput()}
                        <button
                            type="button"
                            className="btn secondary btn-primary h30"
                            title={props.addTitle}
                            onClick={props.onAdd}
                        >
                            <i className="icon-boutons-ajouter-creer-affecter" aria-hidden="true"></i>
                        </button>
                        {renderErrors()}
                        {renderChildren()}
                    </div>
                );
            case 'date':
            case 'datetime':
                return (
                    <div className="flex-label">
                        <label className={labelClassName} htmlFor={id} id={`${id}-label`}>{props.label} : </label>
                        {renderInput()}
                        {renderChildren()}
                        {renderErrors()}
                    </div>
                );
            case 'text':
                return(
                    <div className="flex-label">
                        {renderLabel(':')}
                        {props.clearable ?
                            <div className="field-wrap small">
                                {renderInput()}
                                <button 
                                    type="button"
                                    name={id + "-vider"}
                                    id={id + "-vider"}
                                    title="Vider le champ"
                                    onClick={e => props.onChange('', e)}
                                >
                                    <i className="icon-filtres-vider-champ" aria-hidden="true"></i>
                                </button>
                            </div>
                        :
                            <>{renderInput()}</>
                        }
                        {renderChildren()}
                        {renderErrors()}
                    </div>
                );
            case 'simple':
                return (
                    <div>
                        {renderLabel(':')}
                        {renderInput()}
                    </div>
                );
            case 'preview':
                return(
                    <div className="flex-label">
                        {renderLabel(':')}
                        {renderValue()}
                    </div>
                );
            default:
                return(
                    <div className="flex-label">
                        {renderLabel(':')}
                        {renderInput()}
                        {renderChildren()}
                        {renderErrors()}
                    </div>
                );
        }
    }

    if(props.preview) {
        wrap = true;
        wrapper = "preview";
    }

    if(wrap === true) {
        return renderWrapper();
    } else {
        return (<>
            {renderInput()}
            {renderErrors()}
        </>)
    }
}

FieldComponent.defaultProps = {
    type: "text",
    className: "field",
    wrapButtonsClassName: "statuts",
    disabled: false,
    prefixId: "",
    wrap: true,
    useMapper: true,
    updateMapperValues: true,
    preview: false,
    showRequirements: false,
    rtelight: null,
};
