import _ from "lodash";

export function getActivityDomain() {
    let activityDomains = [
        {value: "Construction", label: "Construction / Immobilier / Habitat"},
        {value: "Distribution", label: "Distribution / Commerce"},
        {value: "TransportLogistique", label: "Transport / Logistique"},
        {value: "Gouvernement", label: "Gouvernement / administration publiques / Enseignement"},
        {value: "Social", label: "Etablissements social et santé"},
        {value: "Energie", label: "Energie / environnement / eau / Facility managment"},
        {value: "Hotellerie", label: "Hotellerie / Loisirs"},
        {value: "Services", label: "Services : Banque Assurances &amp; services / Autres services"}, 
        {value: "Telecom", label: "Télécom"},
        {value: "Aerospatial", label: "Aérospatial / armement"},
        {value: "IndustrieTransport", label: "Industrie Transport & équipementiers (pneu, ...)"},
        {value: "Pharmacie", label: "Pharmacie / Chimie / Phyto / Agroalimentaire"},
        {value: "Petrole", label: "Pétrole et gas (raffinerie / extraction / distribution / stockage)"},
        {value: "Nucleaire", label: "Nucléaire (filière Areva, IRSN, CEA, CNPE, ...)"},
        {value: "Industries", label: "Industries (Equipement, électrique, extractives)"},
        {value: "Autre", label: "Autres (dont agriculture, particuliers, sans NAF, ...)"}
    ];

    return _.sortBy(activityDomains, ['label']);
}