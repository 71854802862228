import _ from 'lodash';
import RequirementApi from 'shared/api/Requirement';
import { compileDataToSelectOptions } from 'shared/services/Utils';

function RequirementAutocomplete(criterias, callback, orderingField = "id", orderingDirection = 'ASC') {

    if (_.isNil(criterias.keyword) || criterias.keyword === "" || criterias.keyword.length < 1) {
        callback([]);
    }

    let queryArgs = {
        "bool": {
            "must": []
        }
    };

    if (criterias.keyword) {
        queryArgs["bool"]["must"].push({
            "bool": {
                "should": [
                    {"match": {"id": parseInt(criterias.keyword) ? parseInt(criterias.keyword) : 0}},
                ]
            }
        });
    }

    if(criterias.excludeIds && criterias.excludeIds.length > 0) {
        queryArgs["bool"]["must"].push({
            "bool": {
                "must_not": [
                    {"terms": {"id": criterias.excludeIds}}
                ]
            }
        });
    }

    RequirementApi
        .search(queryArgs, 0, 25, orderingField, orderingDirection)
        .then(([requirements]) => {
            callback(
                compileDataToSelectOptions(requirements, 'id', ['id'])
            );
        });
}

export default _.debounce(RequirementAutocomplete, 300);

export function getRequirementDefaultOptions(values, orderingField = "id", orderingDirection = 'ASC') {
    return RequirementApi
        .search(
            {"bool": {"must": [{"terms": {"id": values}}]}},
            0,
            25,
            orderingField,
            orderingDirection
        )
        .then(([requirements]) => compileDataToSelectOptions(requirements, 'id', ['id']));
}