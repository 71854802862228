import { useState } from 'react';
import { prefixLinkTo } from "shared/services";

export function useForm(defaultData = {}) {
    const [hasModifications, setHasModifications] = useState(false);
    const [formData, setFormData] = useState(defaultData);
    const [formErrors, setFormErrors] = useState({});

    function setValue(properties, value, triggerModificationStatus = true, callback = null) {
        if (triggerModificationStatus) {
            setHasModifications(true);
        }
        let newFormData;
        if(properties instanceof Object) {
            newFormData = {...formData};
            Object.entries(properties).forEach((change) => {
                newFormData[change[0]] = change[1];
            });
            setFormData(newFormData);
        } else {
            newFormData = Object.assign({}, formData, {[properties]: value});
            setFormData(newFormData);
        }
        if (callback) {
            callback(newFormData);
        }
    }

    function reloadOrClose(event, props, route, id, goback = false) {
        setHasModifications(false);
        if (event.nativeEvent.submitter.name === "save-and-close") {
            if (goback) {
                props.history.goBack();
            } else {
                props.history.push(prefixLinkTo() + '/' + route);
            }            
        } else {
            props.history.push(prefixLinkTo() + '/' + route + '/' + id);
        }
    }

    function formHasModifications() {
        return hasModifications;
    }

    return [formData, formErrors, setFormErrors, setValue, setFormData, reloadOrClose, formHasModifications, setHasModifications];
}