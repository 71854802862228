import AbstractApi from 'shared/api/Abstract';
import ApiRequest from 'shared/services/ApiRequest';
import FileApi from 'shared/api/File';

const PREFIX = "documentaryspace";

const DocumentarySpaceApi = {
    search: function(query, from, size, sorting, direction, additionnalParams) {
        return AbstractApi.search(
            PREFIX + "/" + additionnalParams.type + "/searches",
            query,
            from,
            size,
            sorting,
            direction
        );
    },

    classifySearch: function(query, from, size, classification, additionnalParams) {
        if (classification === "noclassification") {
            return this.search(query, from, size, "date", "DESC", additionnalParams);
        }

        let sorting = [{[classification]: "ASC"}, {"date": "DESC"}];
        if (classification === "domain") {
            sorting = [{"domain": "ASC"}, {"subdomain": "ASC"}, {"theme": "ASC"}, {"date": "DESC"}];
        }
        return ApiRequest
            .post(
                PREFIX + "/" + additionnalParams.type + "/searches",
                {
                    "query": query,
                    "from": from,
                    "size": size,
                    "sort": sorting
                }
            ).then(response => [
                response.data.results, 
                response.data.count,
                response.data.total
            ]);
    },

    get: function(type, id) {
        return AbstractApi.get(PREFIX + "/" + type, id);
    },

    save: function(type, data) {
        return AbstractApi.save(PREFIX + "/" + type, data);
    },

    sendNewsletter: function(id) {
        return ApiRequest.post(PREFIX + "/newsletters/" + id + "/send").then(response => response.data);
    },

    delete: function(type, id) {
        return AbstractApi.delete(PREFIX + "/" + type, id);
    },

    uploadPdf: function(files, params) {
        return FileApi.upload(PREFIX + "/upload-pdf/" + params.entity + "/" + params.field, files);
    },

    uploadImage: function(files, params) {
        return FileApi.upload(PREFIX + "/upload-image/" + params.entity + "/" + params.field, files);
    },
}
export default DocumentarySpaceApi;