import ApiRequest from 'shared/services/ApiRequest';

const ExportApi = {

    list: function(type = '') {
        let endpoint = "export/";
        if (type !== '') {
            endpoint += type;
        }
        return ApiRequest.get(endpoint).then(res => res.data);
    },

    download: function(id) {
        return ApiRequest.get("export/download/" + id, {responseType: 'blob'}).then(res => res.data);
    },

    checkForNew: function() {
        return ApiRequest.get("export/checkfornew").then(res => res.data);
    },

    getReadyExportAmount: function() {
        return ApiRequest.get("export/readyamount").then(res => res.data);
    },

    getTotalAmount: function() {
        return ApiRequest.get("export/totalamount").then(res => res.data);
    },
}

export default ExportApi;