import FileApi from 'shared/api/File';
import AbstractApi from 'shared/api/Abstract';
import ApiRequest from 'shared/services/ApiRequest';

const SheetApi = {

    save: function(data, isPrivate = false) {
        return AbstractApi.save("sheets" + (isPrivate ? "/private" : ""), data).then(response => [response.message, response.result]);
    },

    bulkUpdate: function(ids, data) {
        return ApiRequest.patch(
            "sheets/bulk/update",
            {
                "ids": ids,
                "data": data
            }
        );
    },

    bulkArchive: function(ids) {
        return ApiRequest.patch(
            "sheets/bulk/archive",
            {
                "ids": ids,
                "data": {
                    status: 4
                }
            }
        );
    },

    bulkUpdateSynthesisState: function(ids, state, librarian) {
        return ApiRequest.patch(
            "sheets/bulk/update-synthesis-state",
            {
                "ids": ids,
                "data": {
                    state_synthesis: state,
                    librarian: librarian
                }
            }
        );
    },

    bulkUpdateTechnicalState: function(ids, state, expert) {
        return ApiRequest.patch(
            "sheets/bulk/update-technical-state",
            {
                "ids": ids,
                "data": {
                    state_technical: state,
                    expert: expert
                }
            }
        );
    },

    search: function(query, from, size, sorting, direction) {
        let data = AbstractApi.generateSearchBody(query, from, size, sorting, direction);
        if (data.sort.filter((sorting) => sorting.id).length === 0) {
            data.sort.push({id: "asc"});
        }
        return ApiRequest
            .post("sheets/searches?useSolr", data)                
            .then(response => [
                response.data.results, 
                response.data.count,
                response.data.total
            ]);
    },

    uploadDispatchImage: function(files) {
        return FileApi.upload("sheets/upload/dispatchimage", files);
    },

    uploadAttachments: function(files) {
        return FileApi.upload("sheets/upload/attachments", files);
    },

    uploadReglementaryText: function(files) {
        return FileApi.upload("sheets/upload/reglementarytext", files);
    },

    get: function(id, isPrivate = false) {
        return AbstractApi.get("sheets" + (isPrivate ? "/private" : ""), id);
    },

    hasRequirements: function(id) {
        return ApiRequest.get("sheets/hasrequirements/" + id).then(response => response.data);
    },

    importRequirements: function(id, file) {
        const formData = new FormData();
        formData.append('file', file);
        return ApiRequest
            .post(
                "sheets/importrequirements/" + id,
                formData,
                {
                    headers: {'Content-Type': 'multipart/form-data'}
                }
            )
            .then(response => response.data);
    },

    exportRequirements: function(id) {
        return ApiRequest
            .post("sheets/exportsrequirements/" + id, {}, {responseType: 'blob'})
            .then(response => response.data);
    },

    exportObligations: function(query, fileName = "") {
        let params = {"query": query};
        if (fileName !== "") {
            params.filename = fileName;
        }
        return ApiRequest
            .post("sheets/exportsobligations", params, {responseType: 'blob'})
            .then(response => response.data);
    },

    exportDashboard: function(query, fileName = "") {
        let params = {"query": query};
        if (fileName !== "") {
            params.filename = fileName;
        }
        return ApiRequest
            .post("sheets/exportsdashboard", params, {responseType: 'blob'})
            .then(response => response.data);
    },

    exportSheets: function(query, fileName = "") {
        let params = {"query": query};
        if (fileName !== "") {
            params.filename = fileName;
        }
        return ApiRequest
            .post("sheets/exportssheets", params, {responseType: 'blob'})
            .then(response => response.data);
    },

    exportPdf: function(id) {
        return ApiRequest
            .post("sheets/exportpdf/" + id, {}, {responseType: 'blob'})
            .then(response => response.data);
    }
}
export default SheetApi;
