import React from 'react';

export default function ColumnsSelectionComponent(props) {
    return (
        <span className="field-wrap">
            <fieldset className="dropdown">
                <legend className="text-normal">{props.label}</legend>
                <div>
                    <ul>
                    {props.options.map((option, index) => {
                        if (option.selectable && option.label) {
                            return (
                                <li key={index}>
                                    <input 
                                        type="checkbox"
                                        id={`column-${option.id}`}
                                        value={option.value}
                                        checked={option.checked}
                                        onChange={props.onChange} 
                                    />
                                    <label htmlFor={`column-${option.id}`}>
                                        {option.label}
                                    </label>
                                </li>
                            );
                        }
                        return null;
                    })}
                    </ul>
                </div>
            </fieldset>
        </span>
    );
}
