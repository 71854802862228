import React from "react";
import HtmlComponent from 'shared/components/HtmlComponent';

export default function PreviewFieldComponent(props) {

    const { label, children } = props;

    let empty = false;
    let flex = props.flex;

    if (!children) {
        empty = true;
    }

    if (typeof(flex) === 'undefined') {
        flex = true;
    }

    return (
        <div style={props.additionalStyle} className={(flex === true ? "flex-label" : false)  + " mb-0"}>
            <strong className={props.labelClassName}>{label}&nbsp;:</strong>
            {empty ? 'N/A' : <>
                {props.fromRte && <div style={{flex: 1, marginTop: "11px"}}><HtmlComponent>{children}</HtmlComponent></div>}
                {!props.fromRte && <span>{children}</span>}
            </>}
        </div>
    );
}

PreviewFieldComponent.defaultProps = {
    labelClassName: "label",
    additionalStyle: {},
    fromRte: false,
}