import ApiRequest from 'shared/services/ApiRequest';
import AbstractApi from 'shared/api/Abstract';

const ExternalUserApi = {

    search: function(query, from, size, sorting, direction) {
        return AbstractApi.search("externalusers/searches", query, from, size, sorting, direction);
    },

    list: function(params = {}) {
        return AbstractApi.list("externalusers", params);
    },

    get: function(id) {
        return AbstractApi.get("externalusers", id);
    },

    save: function(data) {
        return AbstractApi.save("externalusers", data);
    },

    export: function(query) {
        return AbstractApi.export("externalusers", query);
    },

    delete: function(id) {
        return AbstractApi.delete("externalusers", id);
    },

    profileUpdate: function(data) {
        return ApiRequest
            .put("externalusers/profile", data)
            .then(response => response.data);
    }
}
export default ExternalUserApi;