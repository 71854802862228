import React, { createContext, useContext, useReducer } from 'react';

const reducer = (state, action) => {
    switch (action.type) {
        case 'setUser':
            return {
                ...state,
                ...action.user
            };
        default:
            return state;
    }
};

const UserContext = createContext();

export const UserConsumer = UserContext.Consumer;
export const UserConsumerHook = () => useContext(UserContext);

export const UserProvider = ({children}) => (
   <UserContext.Provider value={useReducer(reducer, {})}>
       {children}
   </UserContext.Provider>
);