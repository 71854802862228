import ApiRequest from 'shared/services/ApiRequest';
import AbstractApi from 'shared/api/Abstract';

const ObligationGeneratorApi = {

    importQuestions: function(file) {
        const formData = new FormData();
        formData.append('file', file);
        return ApiRequest
            .post(
                "obligationgenerator/import-questions",
                formData,
                {
                    headers: {'Content-Type': 'multipart/form-data'}
                }
            )
            .then(response => response.data);
    },

    importObligations: function(file) {
        const formData = new FormData();
        formData.append('file', file);
        return ApiRequest
            .post(
                "obligationgenerator/import-obligations",
                formData,
                {
                    headers: {'Content-Type': 'multipart/form-data'}
                }
            )
            .then(response => response.data);
    },

    getAllQuestions: function() {
        return this.getQuestionsByStep("*");
    },

    getQuestionsByStep: function(step) {
        return AbstractApi.get("obligationgenerator/questions", step);
    },

    export: function(query, fileName) {
        return AbstractApi.export("obligationgenerator", query, '', fileName);
    },
}
export default ObligationGeneratorApi;