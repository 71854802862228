import ApiRequest from 'shared/services/ApiRequest';
import AbstractApi from 'shared/api/Abstract';
import FileApi from 'shared/api/File';

const WatchsiteApi = {

    save: function(data) {
        return AbstractApi.save("watchsites", data);
    },

    get: function(id) {
        return AbstractApi.get("watchsites", id);
    },

    list: function(account = null, limit = 200) {
        let params = {};

        if (account) {
            params.account = account;
        }
        if (limit) {
            params.limit = limit;
        }
        return AbstractApi.list("watchsites", params);
    },

    export: function(query) {
        return AbstractApi.export("watchsites", query);
    },

    bulkUpdate: function(ids, data) {
        return ApiRequest.patch(
            "watchsites/bulk/update",
            {
                "ids": ids,
                "data": data
            }
        );
    },

    uploadLogo: function(files) {
        return FileApi.upload("watchsites/upload/logo", files);
    },

    uploadAttachments: function(files) {
        return FileApi.upload("watchsites/upload/attachments", files);
    },
    
    transfer: function(watchsite, account) {
        return ApiRequest.post("watchsites/transfer", {"watchsite": watchsite, "targetaccount": account});
    }
}
export default WatchsiteApi;
