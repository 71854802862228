import React, { useState, useEffect, useContext } from 'react';
import { Link, Prompt, useHistory } from "react-router-dom";
import { toast } from 'react-toastify';

import DefaultLayout from "layouts/DefaultLayout";

import { useForm } from 'shared/hooks/Form';
import { useSecurity } from 'shared/hooks/Security';
import { useToggler } from "shared/hooks/Toggler";
import { ReferentialContext } from 'shared/stores/Referential';

import { getCodeNaf } from 'data/CodeNaf';

import AccountApi from 'shared/api/Account';
import InternalUserApi from 'shared/api/InternalUser';
import ExternalUserApi from 'shared/api/ExternalUser';

import Can, { canPerform, denyIfCantPerform } from 'shared/services/Can';
import { compileErrorsFromResponse } from 'shared/services/Utils';

import FieldComponent from 'shared/components/FieldComponent';
import FileComponent from 'shared/components/FileComponent';
import SidebarComponent from 'shared/components/SidebarComponent';
import LoadButton from 'shared/components/LoadButton';
import ToggableBlocComponent from "shared/components/ToggableBlocComponent";
import PageLoadingComponent from 'shared/components/PageLoadingComponent';

import Contracts from 'views/account/form/Contracts';
import AccountTree from 'views/account/form/AccountTree';
import { REFERENTIAL_DOMAIN } from 'shared/data/ReferentialType';

export default function AccountForm(props) {

    denyIfCantPerform(props, "account:read");

    const history = useHistory();
    const [user,,,, isAdmin] = useSecurity();
    const [togglers, toggle] = useToggler({
        'configuration': true,
        'access': true,
        'contracts': true,
        'api-configuration': true,
        'identity-checking': true,
        'provisioning-rule': true,
        'other-parameters': true,
        'nodes': true
    });
    const [data, errors, setErrors, setValue, setData, reloadOrClose, formHasModifications] = useForm({
        id: null,
        name: "",
        active: 0,
        is_start: false,
        naf: "",
        address: "",
        zipcode: "",
        city: "",
        max_users: 100,
        apave_pilot: "",
        apave_feed: "",
        logo: null,
        siren: "",
        access_newsletter: 0,
        access_documentary_space: 0,
        access_private_documentary_space: 0,
        access_hotline: 0,
        access_synthesis_level: 0,
        access_technical_level: 0,
        access_consultant_level: 0,
        access_headquarter_comment: 0,
        access_apave_database: 0,
        access_customer_comment: 0,
        access_alert_filters: 0,
        access_actionplan: 0,
        access_compliance_management: 0,
        access_categories: 0,
        access_notfinalized_sheetalert: 0,
        access_obligation_generator: 0,
        accessible_to_apave: 1,
        contracts: [],
        pilot_consultants: user.roles.includes("ROLE_CONSULTANT") ? [user.id] : [],
        associate_consultants: [],
        comment: "",
        has_domain_restriction: false,
        domains: [],
        available_alert_frequencies: [0, 1, 2, 3, 4, 5],
        identitychecking: false,
        identitychecking_protocol: null,
        openid_url: null,
        openid_clientid: null,
        openid_clientsecret: null,
        idp_entity_id: null,
        idp_sso_url: null,
        idp_certificate: null,
        provisioning_rule: false,
        fid_profil: 'ROLE_USER_CUSTOMER',
        fid_access_user_configuration: false,
        fid_access_tree_management: false,
        fid_access_profil: false,
        fid_access_write: false,
        fid_access_write_hotline: false,
        fid_alert_documentary_space_activation: false,
        fid_alert_activation: false,
        fid_alert_frequency: 0,
        fid_alert_review_activation: false,
        fid_alert_deadline_activation: false,
        fid_watchsites_access: 0,
        anonymization_hotline: false,
        anonymization_hotline_duration: 6,
        oidc_code: null,
        webservices: [],
        webservices_user: null,
    });

    const [saving, setSaving] = useState("");
    const [is_load, setIsLoad] = useState((!props.match.params.id));
    const [available_consultants, setAvailableConsultants] = useState([]);
    const [available_pilot_consultants, setAvailablePilotConsultants] = useState([]);
    const [available_associate_consultants, setAvailableAssociateConsultants] = useState([]);    
    const [account_users, setAccountUsers] = useState([]);
    const [account_consultants, setAccountConsultants] = useState([]);
    const [referentialContext] = useContext(ReferentialContext);
    const domains = []; 
    Object.entries(referentialContext[REFERENTIAL_DOMAIN]).forEach(([index, domain]) => {
        domains.push({"value": domain.id, "label": domain.value});
    });
    const [piloteStartConsultantId, setPiloteStartConsultantId] = useState(null);

    useEffect(() => {
        if (piloteStartConsultantId === null) {
            AccountApi.getPilotStartConsultant()
                .then(user => setPiloteStartConsultantId(user.id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (props.match.params.id) {
            AccountApi.get(props.match.params.id)
                .then(freshData => {
                    setIsLoad(true);
                    freshData.pilot_consultants = freshData.pilot_consultants.map(c => c.id);
                    freshData.associate_consultants = freshData.associate_consultants.map(c => c.id);
                    freshData.webservices_user = freshData.webservices_user ? freshData.webservices_user.id : null;
                    freshData.domains = freshData.domains.map(d => d.id);
                    freshData.available_alert_frequencies = freshData.available_alert_frequencies ? freshData.available_alert_frequencies : []
                    setData(d => Object.assign({}, d, freshData));
                })
                .catch(() => {
                    toast.error("Une erreur est survenue lors de la récupération du compte");
                    history.goBack();
                });

                ExternalUserApi
                    .search({"bool":{"must":[{"match":{"account_id":props.match.params.id}}]}})
                    .then(([results]) => {
                        let newAccountUsers = [];
                        Object.entries(results).forEach(([index, user]) => {
                            newAccountUsers.push({
                                "value": user.user_id,
                                "label": user.lastname + " " + user.firstname + "(" + user.email + ")"});
                        });
                        setAccountUsers(newAccountUsers);
                    })
                    .catch(error => console.log(error));
        }

        InternalUserApi
            .list("ROLE_CONSULTANT")
            .then(([results]) => {
                let newconsultants = [];
                Object.entries(results).forEach(([index, c]) => {
                    newconsultants.push({"value": c.id, "label": c.lastname + " " + c.firstname});
                });
                newconsultants.sort((a, b) => (a.label > b.label) ? 1 : -1)
                setAvailableConsultants(newconsultants);
            })
            .catch(error => console.log(error));

            if (!props.match.params.id) {
                // -- Default domains values
                let values = [];
                Object.entries(domains).forEach(([index, domain]) => {
                    values.push(domain.value);
                });
                setData(d => Object.assign({}, d, {domains: values}));
            }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let newAccountConsultants = [];
        let newAvailablePilotConsultants = [];
        let newAvailableAssociateConsultants = [];

        available_consultants.map(c => {
            if (data.pilot_consultants.includes(c.value) || data.associate_consultants.includes(c.value)) {
                newAccountConsultants.push(c);
            }

            if (!data.pilot_consultants.includes(c.value)) {
                newAvailableAssociateConsultants.push(c);
            }

            if (!data.associate_consultants.includes(c.value)) {
                newAvailablePilotConsultants.push(c);
            }

            return c;
        });
        newAccountConsultants = filterPilotStartConsultant(newAccountConsultants);
        newAvailablePilotConsultants = filterPilotStartConsultant(newAvailablePilotConsultants);
        newAvailableAssociateConsultants = filterPilotStartConsultant(newAvailableAssociateConsultants);

        setAccountConsultants(newAccountConsultants);
        setAvailablePilotConsultants(newAvailablePilotConsultants);
        setAvailableAssociateConsultants(newAvailableAssociateConsultants);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.pilot_consultants, data.associate_consultants, available_consultants]);

    function updateStart(newValue) {
        if (newValue === "1") {
            setData({...data, ...{
                is_start: true,
                available_alert_frequencies: [5],
                access_newsletter: 0,
                access_documentary_space: 0,
                access_private_documentary_space: 0,
                access_technical_level: 0,
                access_consultant_level: 0,
                access_headquarter_comment: 0,
                access_customer_comment: 0,
                access_alert_filters: 0,
                access_categories: 0,
                access_notfinalized_sheetalert: 0,
                access_apave_database: 0,

                access_hotline: 1,
                access_synthesis_level: 1,
                access_actionplan: 1,
                access_compliance_management: 1,

                identitychecking: false,
            }});
        } else {
            setData({...data, ...{
                is_start: false,
                pilot_consultants: user.roles.includes("ROLE_CONSULTANT") ? [user.id] : []
            }});
        }
    }

    function filterPilotStartConsultant(consultantList) {
        return consultantList.filter(c => {
            return c.value !== piloteStartConsultantId;
        });
    }

    function submit(event) { 
        event.preventDefault();
        if (event.target.id === "form-account") {

            setSaving(event.nativeEvent.submitter.name);

            let apiData = {...data};
            apiData.logo = data.logo ? data.logo.hash : null;

            AccountApi
                .save(apiData)
                .then(newData => {
                    setData(Object.assign({}, data, {"id": newData.id}));
                    setErrors({});
                    setSaving("");
                    toast.success("Enregistrement effectué.");
                    reloadOrClose(event, props, "accounts", newData.id);
                })
                .catch(error => {
                    setSaving("");
                    if (error.response.data.message) {
                        toast.error(error.response.data.message);
                    } else {
                        setErrors(compileErrorsFromResponse(error.response));
                        toast.error("Des erreurs sont survenues");
                    }
                });
        }
    }

    function updatePilotConsultant(newvalues) {
        if (user.roles.includes("ROLE_CONSULTANT")
            && !newvalues.includes(user.id)
            && data.pilot_consultants.includes(user.id)
        ) {
            toast.warning("Vous ne pouvez pas vous enlever d'un compte");
        } else {
            setValue("pilot_consultants", newvalues);
        }
    }

    function updateApi(api, checked) {
        let newApi = [...data.webservices];
        if (checked) {
            newApi.push(api);
        } else {
            newApi = newApi.filter(a => a !== api);
        }
        setValue("webservices", newApi);
    }

    if (is_load !== true) {
        return <PageLoadingComponent label="Edition d'un compte" />
    }

    return (
        <DefaultLayout screen="E05" title="Configuration du compte">
            <Prompt
                when={formHasModifications()}
                message="Vous avez des modifications non enregistrées, voulez-vous vraiment quitter ?"
            />
            <section className="sidebar">
                <h1>Configuration du compte</h1>
                <SidebarComponent
                    label="Configuration du compte"
                    links={[
                        {anchor: "#configuration", label: "Configuration du compte", active: true},
                        {anchor: "#access", label: "Configuration des options d'accès", active: true},
                        {anchor: "#contracts", label: "Configuration des contrats", active: true},
                        {anchor: "#api-configuration", label: "Affectation des webservices", active: isAdmin()},
                        {anchor: "#identity-checking", label: "Vérification d'identité", active: isAdmin()},
                        {anchor: "#provisioning-rule", label: "Configuration FID", active: isAdmin()},
                        {anchor: "#other-parameters", label: "Autres paramètres", active: isAdmin()},
                        {anchor: "#nodes", label: "Configuration de l'arborescence", active: data.id ? true : false}
                    ]}
                />
                <section>
                    <form onSubmit={submit} id="form-account" className="form">
                        <ToggableBlocComponent label="Configuration du compte" id="configuration" toggled={togglers["configuration"]} toggle={toggle}>
                            <div className="bg-blanc">
                                {data.id && <p className="text-right">Numéro du compte : {data.id} {data.import_id && <>| {data.import_id}</>}</p>}
                                <FieldComponent
                                    name="name"
                                    label="Raison sociale *"
                                    error={errors.name}
                                    value={data.name}
                                    onChange={value => setValue("name", value)}
                                    disabled={!canPerform("account:write", data)}
                                />
                                <FieldComponent
                                    type="checksingle"
                                    name="active"
                                    label="Compte activé"
                                    checksingleLabel="Oui"
                                    onChange={value => setValue("active", (value ? 1 : 0))}
                                    checked={data.active}
                                    disabled={!canPerform("account:write", data)}
                                />
                                <FieldComponent
                                    type="radio"
                                    name="is_start"
                                    label="Offre"
                                    onChange={updateStart}
                                    value={data.is_start ? 1 : 0}
                                    error={errors.is_start}
                                    options={[
                                        {"value": 1, "label": "Start"},
                                        {"value": 0, "label": "Premium"}
                                    ]}
                                    disabled={!canPerform("account:write", data)}
                                />

                                <FileComponent
                                    label="Logo"
                                    extensions={[".png", ".jpg", ".jpeg", ".gif"]}
                                    value={data.logo}
                                    name="logo"
                                    onChange={value => setValue("logo", value)}
                                    upload={AccountApi.uploadLogo}
                                    disabled={!canPerform("account:write", data)}                                      
                                />
                                <FieldComponent
                                    name="siren"
                                    label="Siren *"
                                    onChange={value => setValue("siren", value)}
                                    value={data.siren}
                                    error={errors.siren}
                                    disabled={!canPerform("account:write", data)}
                                />
                                <FieldComponent
                                    type="select"
                                    name="naf"
                                    label="Code NAF *"
                                    className="field small"
                                    onChange={value => setValue("naf", value)}
                                    value={data.naf}
                                    error={errors.naf}
                                    options={getCodeNaf().map(({code, label}) => { return {"label": label, "value": code}})}
                                    disabled={!canPerform("account:write", data)}
                                />
                                <FieldComponent
                                    type="textarea"
                                    name="address"
                                    className="field h-100px"
                                    label="Adresse *"
                                    onChange={value => setValue("address", value)}
                                    value={data.address}
                                    error={errors.address}
                                    disabled={!canPerform("account:write", data)}
                                />
                                <FieldComponent
                                    name="zipcode"
                                    label="Code postal *"
                                    error={errors.zipcode}
                                    onChange={value => setValue("zipcode", value)}
                                    value={data.zipcode}
                                    disabled={!canPerform("account:write", data)}
                                />
                                <FieldComponent
                                    name="city"
                                    label="Ville *"
                                    error={errors.city}
                                    onChange={value => setValue("city", value)}
                                    value={data.city}
                                    disabled={!canPerform("account:write", data)}
                                />
                                <FieldComponent
                                    type="select"
                                    name="apave_pilot"
                                    label="Apave pilote"
                                    onChange={value => setValue("apave_pilot", value)}
                                    value={data.apave_pilot}
                                    clearable={true}
                                    options={[
                                        {value:"Alsacienne", label: "Alsacienne"},
                                        {value:"Nord-ouest", label: "Nord-ouest"},
                                        {value:"Parisienne", label: "Parisienne"},
                                        {value:"Sud Europe", label: "Sud Europe"}
                                    ]}
                                    disabled={!canPerform("account:write", data)}
                                />
                                <FieldComponent
                                    type="select"
                                    name="apave_feed"
                                    label="Apave qui alimente"
                                    onChange={value => setValue("apave_feed", value)}
                                    value={data.apave_feed}
                                    clearable={true}
                                    options={[
                                        {value:"Alsacienne", label: "Alsacienne"},
                                        {value:"Nord-ouest", label: "Nord-ouest"},
                                        {value:"Parisienne", label: "Parisienne"},
                                        {value:"Sud Europe", label: "Sud Europe"}
                                    ]}
                                    disabled={!canPerform("account:write", data)}
                                />
                                {data.is_start && <div class="flex-label">
                                    <strong class="label">Consultants&nbsp;:</strong>
                                    <span>
                                        <input class="field" type="text" disabled value="Pilot Veille Start" />
                                    </span>
                                </div>}
                                {!data.is_start && <>
                                    <FieldComponent
                                        type="select"
                                        multiple={true}
                                        name="pilot_consultants"
                                        label="Consultants pilotes *"
                                        onChange={values => updatePilotConsultant(values)}
                                        value={data.pilot_consultants}
                                        options={available_pilot_consultants}
                                        error={errors.pilot_consultants}
                                        disabled={!canPerform("account:write", data) || data.is_start}
                                    />
                                    <FieldComponent
                                        type="select"
                                        multiple={true}
                                        name="associate_consultants"
                                        label="Consultants associés"
                                        onChange={values => setValue("associate_consultants", values)}
                                        value={data.associate_consultants}
                                        options={available_associate_consultants}
                                        error={errors.associate_consultants}
                                        disabled={!canPerform("account:write", data) || data.is_start}
                                    />
                                </>}
                                <FieldComponent
                                    type="radio"
                                    name="has_domain_restriction"
                                    label="Possède des restrictions sur les domaines"
                                    onChange={value => setValue("has_domain_restriction", parseInt(value) === 1 ? true : false)}
                                    value={data.has_domain_restriction ? 1 : 0}
                                    options={[
                                        {"value": 1, "label": "Oui"},
                                        {"value": 0, "label": "Non"}
                                    ]}
                                    disabled={!canPerform("account:write", data)}
                                />
                                {data.has_domain_restriction && <FieldComponent
                                    type="checkboxes"
                                    multiple={true}
                                    name="domains"
                                    label="Domaines"
                                    onChange={values => setValue("domains", values)}
                                    value={data.domains}
                                    options={domains}
                                    error={errors.domains}
                                    disabled={!canPerform("account:write", data)}
                                />}
                                <FieldComponent
                                    type="number"
                                    name="max_users"
                                    label="Nombre d'utilisateurs maximum *"
                                    pattern="[0-9]+"
                                    onChange={value => setValue("max_users", parseInt(value))}
                                    value={data.max_users}
                                    error={errors.max_users}
                                    disabled={!canPerform("account:write", data)}
                                />
                                <FieldComponent
                                    type="radio"
                                    name="accessible_to_apave"
                                    label="Données accessibles à Apave"
                                    onChange={value => setValue("accessible_to_apave", parseInt(value))}
                                    value={data.accessible_to_apave}
                                    options={[
                                        {"value": 1, "label": "Oui"},
                                        {"value": 0, "label": "Non"}
                                    ]}
                                    disabled={!canPerform("account:write", data)}
                                />
                                <FieldComponent
                                    type="checkboxes"
                                    name="available_alert_frequencies"
                                    label="Fréquence des alertes disponibles pour le compte"
                                    options={[
                                        {value:0, label: "Quotidienne", disabled: data.is_start},
                                        {value:1, label: "Quotidienne + Récapitulatif mensuel", disabled: data.is_start},
                                        {value:2, label: "Hebdomadaire", disabled: data.is_start},
                                        {value:3, label: "Hebdomadaire + Récapitulatif mensuel", disabled: data.is_start},
                                        {value:4, label: "Bimensuelle", disabled: data.is_start},
                                        {value:5, label: "Mensuelle", disabled: data.is_start},
                                    ]}
                                    error={errors.available_alert_frequencies}
                                    value={data.available_alert_frequencies || []}
                                    onChange={value => setValue("available_alert_frequencies", value)}
                                />
                                <FieldComponent
                                    type="textarea"
                                    name="comment"
                                    className="field h-100px"
                                    label="Commentaire interne"
                                    onChange={value => setValue("comment", value)}
                                    value={data.comment}
                                    disabled={!canPerform("account:write", data)}
                                />
                            </div>
                        </ToggableBlocComponent>
                        <ToggableBlocComponent label="Configuration des options" id="access" toggled={togglers["access"]} toggle={toggle}>
                            <div className="bg-blanc">
                                <div className="offset-label">
                                    <div className="row checkboxes">
                                        <fieldset className="col-md-6"><div>
                                            <input 
                                                type="checkbox"
                                                name="access_newsletter"
                                                id="access_newsletter"
                                                onChange={e => setValue("access_newsletter", (e.target.checked ? 1 : 0))}
                                                checked={data.access_newsletter}
                                                disabled={!canPerform("account:write", data) || data.is_start}
                                            />
                                            <label htmlFor="access_newsletter" id="access_newsletter-label">Possibilité d’abonnement newsletter Espace documentaire</label>
                                        </div></fieldset>
                                        <fieldset className="col-md-6"><div>
                                            <input 
                                                type="checkbox"
                                                name="access_documentary_space"
                                                id="access_documentary_space"
                                                onChange={e => setValue("access_documentary_space", (e.target.checked ? 1 : 0))}
                                                checked={data.access_documentary_space}
                                                disabled={!canPerform("account:write", data) || data.is_start}
                                            />
                                            <label htmlFor="access_documentary_space" id="access_documentary_space-label">Espace documentaire</label>
                                        </div></fieldset>
                                        <fieldset className="col-md-6"><div>
                                            <input 
                                                type="checkbox"
                                                name="access_private_documentary_space"
                                                id="access_private_documentary_space"
                                                onChange={e => setValue("access_private_documentary_space", (e.target.checked ? 1 : 0))}
                                                checked={data.access_private_documentary_space}
                                                disabled={!canPerform("account:write", data) || data.is_start}
                                            />
                                            <label htmlFor="access_private_documentary_space" id="access_private_documentary_space-label">Module « Mes documents privés »</label>
                                        </div></fieldset>
                                        <fieldset className="col-md-6"><div>
                                            <input 
                                                type="checkbox"
                                                name="access_hotline"
                                                id="access_hotline"
                                                onChange={e => setValue("access_hotline", (e.target.checked ? 1 : 0))}
                                                checked={data.access_hotline}
                                                disabled={!canPerform("account:write", data) || data.is_start}
                                            />
                                            <label htmlFor="access_hotline" id="access_hotline-label">Hotline</label>
                                        </div></fieldset>
                                        <fieldset className="col-md-6"><div>
                                            <input 
                                                type="checkbox"
                                                name="access_synthesis_level"
                                                id="access_synthesis_level"
                                                onChange={e => setValue("access_synthesis_level", (e.target.checked ? 1 : 0))}
                                                checked={data.access_synthesis_level}
                                                disabled={!canPerform("account:write", data) || data.is_start}
                                            />
                                            <label htmlFor="access_synthesis_level" id="access_synthesis_level-label">Accès Niveau synthèse</label>
                                        </div></fieldset>
                                        <fieldset className="col-md-6"><div>                                            
                                            <input 
                                                type="checkbox"
                                                name="access_technical_level"
                                                id="access_technical_level"
                                                onChange={e => setValue("access_technical_level", (e.target.checked ? 1 : 0))}
                                                checked={data.access_technical_level}
                                                disabled={!canPerform("account:write", data) || data.is_start}
                                            />
                                            <label htmlFor="access_technical_level" id="access_technical_level-label">Accès Niveau technique</label>
                                        </div></fieldset>
                                        <fieldset className="col-md-6"><div>
                                            <input 
                                                type="checkbox"
                                                name="access_consultant_level"
                                                id="access_consultant_level"
                                                onChange={e => setValue("access_consultant_level", (e.target.checked ? 1 : 0))}
                                                checked={data.access_consultant_level}
                                                disabled={!canPerform("account:write", data) || data.is_start}
                                            />
                                            <label htmlFor="access_consultant_level" id="access_consultant_level-label">Accès Commentaire consultant</label>
                                        </div></fieldset>
                                        <fieldset className="col-md-6"><div>
                                            <input 
                                                type="checkbox"
                                                name="access_headquarter_comment"
                                                id="access_headquarter_comment"
                                                onChange={e => setValue("access_headquarter_comment", (e.target.checked ? 1 : 0))}
                                                checked={data.access_headquarter_comment}
                                                disabled={!canPerform("account:write", data) || data.is_start}
                                            />
                                            <label htmlFor="access_headquarter_comment" id="access_headquarter_comment-label">Saisie de Commentaire Siège</label>
                                        </div></fieldset>
                                        <fieldset className="col-md-6"><div>
                                            <input 
                                                type="checkbox"
                                                name="access_apave_database"
                                                id="access_apave_database"
                                                onChange={e => setValue("access_apave_database", (e.target.checked ? 1 : 0))}
                                                checked={data.access_apave_database}
                                                disabled={!canPerform("account:write", data) || data.is_start}
                                            />
                                            <label htmlFor="access_apave_database" id="access_apave_database-label">Accès Base Apave</label>
                                        </div></fieldset>
                                        <fieldset className="col-md-6"><div>
                                            <input 
                                                type="checkbox"
                                                name="access_customer_comment"
                                                id="access_customer_comment"
                                                onChange={e => setValue("access_customer_comment", (e.target.checked ? 1 : 0))}
                                                checked={data.access_customer_comment}
                                                disabled={!canPerform("account:write", data) || data.is_start}
                                            />
                                            <label htmlFor="access_customer_comment" id="access_customer_comment-label">Saisie de Commentaire client</label>
                                        </div></fieldset>
                                        <fieldset className="col-md-6"><div>
                                            <input 
                                                type="checkbox"
                                                name="access_alert_filters"
                                                id="access_alert_filters"
                                                onChange={e => setValue("access_alert_filters", (e.target.checked ? 1 : 0))}
                                                checked={data.access_alert_filters}
                                                disabled={!canPerform("account:write", data) || data.is_start}
                                            />
                                            <label htmlFor="access_alert_filters" id="access_alert_filters-label">Filtrage personnalisé des alertes</label>
                                        </div></fieldset>
                                        <fieldset className="col-md-6"><div>
                                            <input 
                                                type="checkbox"
                                                name="access_actionplan"
                                                id="access_actionplan"
                                                onChange={e => setValue("access_actionplan", (e.target.checked ? 1 : 0))}
                                                checked={data.access_actionplan}
                                                disabled={!canPerform("account:write", data) || data.is_start}
                                            />
                                            <label htmlFor="access_actionplan" id="access_actionplan-label">Plan d'action</label>
                                        </div></fieldset>
                                        <fieldset className="col-md-6"><div>
                                            <input 
                                                type="checkbox"
                                                name="access_compliance_management"
                                                id="access_compliance_management"
                                                onChange={e => setValue("access_compliance_management", (e.target.checked ? 1 : 0))}
                                                checked={data.access_compliance_management}
                                                disabled={!canPerform("account:write", data) || data.is_start}
                                            />
                                            <label htmlFor="access_compliance_management" id="access_compliance_management-label">Gestion de la conformité</label>
                                        </div></fieldset>
                                        <fieldset className="col-md-6"><div>
                                            <input 
                                                type="checkbox"
                                                name="access_categories"
                                                id="access_categories"
                                                onChange={e => setValue("access_categories", (e.target.checked ? 1 : 0))}
                                                checked={data.access_categories}
                                                disabled={!canPerform("account:write", data) || data.is_start}
                                            />
                                            <label htmlFor="access_categories" id="access_categories-label">Catégorisation</label>
                                        </div></fieldset>
                                        <fieldset className="col-md-6"><div>
                                            <input 
                                                type="checkbox"
                                                name="access_notfinalized_sheetalert"
                                                id="access_notfinalized_sheetalert"
                                                onChange={e => setValue("access_notfinalized_sheetalert", (e.target.checked ? 1 : 0))}
                                                checked={data.access_notfinalized_sheetalert}
                                                disabled={!canPerform("account:write", data) || data.is_start}
                                            />
                                            <label htmlFor="access_notfinalized_sheetalert" id="access_notfinalized_sheetalert-label">Alertes sur fiches non finalisées</label>
                                        </div></fieldset>
                                    </div>
                                </div>
                            </div>
                        </ToggableBlocComponent>
                        <ToggableBlocComponent label="Configuration des contrats" id="contracts" toggled={togglers["contracts"]} toggle={toggle}>
                            <div className="bg-blanc">
                                <Contracts
                                    contracts={data.contracts}
                                    consultants={account_consultants}
                                    onChange={(e, c) => setValue("contracts", c)}
                                    disabled={!canPerform("account:write", data)}
                                />
                            </div>
                        </ToggableBlocComponent>
                        {isAdmin() && <ToggableBlocComponent label="Affectation des webservices" id="api-configuration" toggled={togglers["api-configuration"]} toggle={toggle}>
                            <div className="bg-blanc">
                                <div className="offset-label">
                                    <div className="row checkboxes">
                                        <fieldset className="col-md-6"><div>
                                            <h5>API permettant d'accéder aux dernières modifications</h5>
                                            <input 
                                                type="checkbox"
                                                name="api_lastmod_01"
                                                id="api_lastmod_01"
                                                onChange={e => updateApi("lastmod_01", e.target.checked)}
                                                checked={data.webservices.includes("lastmod_01")}
                                                disabled={!canPerform("account:write", data)}
                                            />
                                            <label htmlFor="api_lastmod_01" id="api_lastmod_01-label">Version 01</label>
                                        </div></fieldset>
                                        <fieldset className="col-md-6"><div>
                                            <h5>API permettant d'accéder aux informations des fiches</h5>
                                            <input 
                                                type="checkbox"
                                                name="api_publicsheet_01"
                                                id="api_publicsheet_01"
                                                onChange={e => updateApi("publicsheet_01", e.target.checked)}
                                                checked={data.webservices.includes("publicsheet_01")}
                                                disabled={!canPerform("account:write", data)}
                                            />
                                            <label htmlFor="api_publicsheet_01" id="api_publicsheet_01-label">Version 01</label>
                                        </div></fieldset>
                                    </div>
                                    <div className="row checkboxes">
                                        <fieldset className="col-md-6"><div>
                                            <h5>API permettant d'accéder aux informations des exigences</h5>
                                            <input 
                                                type="checkbox"
                                                name="api_requirement_01"
                                                id="api_requirement_01"
                                                onChange={e => updateApi("requirement_01", e.target.checked)}
                                                checked={data.webservices.includes("requirement_01")}
                                                disabled={!canPerform("account:write", data)}
                                            />
                                            <label htmlFor="api_requirement_01" id="api_requirement_01-label">Version 01</label>
                                        </div></fieldset>
                                        <fieldset className="col-md-6"><div>
                                            <h5>API permettant d'accéder aux informations des fiches affectées</h5>
                                            <input 
                                                type="checkbox"
                                                name="api_affectedsheet_01"
                                                id="api_affectedsheet_01"
                                                onChange={e => updateApi("affectedsheet_01", e.target.checked)}
                                                checked={data.webservices.includes("affectedsheet_01")}
                                                disabled={!canPerform("account:write", data)}
                                            />
                                            <label htmlFor="api_affectedsheet_01" id="api_affectedsheet_01-label">Version 01</label>
                                        </div></fieldset>
                                    </div>
                                    <div className="row checkboxes">
                                        <fieldset className="col-md-6"><div>
                                            <h5>API permettant d'accéder aux informations des exigences affectées</h5>
                                            <input 
                                                type="checkbox"
                                                name="api_affectedrequirement_01"
                                                id="api_affectedrequirement_01"
                                                onChange={e => updateApi("affectedrequirement_01", e.target.checked)}
                                                checked={data.webservices.includes("affectedrequirement_01")}
                                                disabled={!canPerform("account:write", data)}
                                            />
                                            <label htmlFor="api_affectedrequirement_01" id="api_affectedrequirement_01-label">Version 01</label>
                                        </div></fieldset>
                                    </div>
                                </div>
                                <FieldComponent
                                    type="select"
                                    name="webservices_user"
                                    label="Utilisateur des webservice"
                                    onChange={value => setValue("webservices_user", value)}
                                    value={data.webservices_user}
                                    options={account_users}
                                    error={errors.webservices_user}
                                    disabled={!canPerform("account:write", data)}
                                    clearable
                                />
                            </div>
                        </ToggableBlocComponent>}

                        {isAdmin() && !data.is_start && <ToggableBlocComponent label="Vérification d'identité" id="identity-checking" toggled={togglers["identity-checking"]} toggle={toggle}>
                            <div className="bg-blanc">
                                <FieldComponent
                                    type="checksingle"
                                    name="identitychecking"
                                    label="Activer la vérification d'identité"
                                    checksingleLabel="Oui"
                                    onChange={value => setValue("identitychecking", value)}
                                    value={data.identitychecking}
                                    checked={data.identitychecking}
                                    disabled={!canPerform("account:write", data)}
                                />
                                {data.identitychecking === true && <>                                    
                                    <FieldComponent
                                        type="select"
                                        name="identitychecking_protocol"
                                        label="Protocole"
                                        className="field small"
                                        onChange={value => setValue("identitychecking_protocol", value)}
                                        value={data.identitychecking_protocol}
                                        options={[
                                            {value: "openid", label: "OpenID"},
                                            {value: "saml2", label: "Saml2"},
                                        ]}
                                        disabled={!canPerform("account:write", data)}
                                        clearable
                                    />
                                </>}
                            </div>
                            {data.identitychecking === true && data.identitychecking_protocol === "openid" && <>
                                <div className="bg-gris-25">
                                    <FieldComponent
                                        name="openid_url"
                                        label="URL de service"
                                        className="field medium"
                                        onChange={value => setValue("openid_url", value)}
                                        value={data.openid_url}
                                        disabled={!canPerform("account:write", data)}
                                    />
                                    <FieldComponent
                                        name="openid_clientid"
                                        label="Client ID"
                                        className="field medium"
                                        onChange={value => setValue("openid_clientid", value)}
                                        value={data.openid_clientid}
                                        disabled={!canPerform("account:write", data)}
                                    />
                                    <FieldComponent
                                        type="password"
                                        name="openid_clientsecret"
                                        label="Client secret"
                                        className="field medium"
                                        onChange={value => setValue("openid_clientsecret", value)}
                                        value={data.openid_clientsecret}
                                        disabled={!canPerform("account:write", data)}
                                    />
                                </div>
                            </>}
                            {data.identitychecking === true && data.identitychecking_protocol === "saml2" && <>
                                <div className="bg-gris-25">
                                    <FieldComponent
                                        name="idp_entity_id"
                                        label="IDP Entité ID"
                                        className="field medium"
                                        onChange={value => setValue("idp_entity_id", value)}
                                        value={data.idp_entity_id}
                                        disabled={!canPerform("account:write", data)}
                                    />
                                    <FieldComponent
                                        name="idp_sso_url"
                                        label="SSO URL"
                                        className="field medium"
                                        onChange={value => setValue("idp_sso_url", value)}
                                        value={data.idp_sso_url}
                                        disabled={!canPerform("account:write", data)}
                                    />
                                    <FieldComponent
                                        type="textarea"
                                        name="idp_certificate"
                                        label="Certificat"
                                        className="field medium"
                                        onChange={value => setValue("idp_certificate", value)}
                                        value={data.idp_certificate}
                                        disabled={!canPerform("account:write", data)}
                                    />
                                </div>
                            </>}
                        </ToggableBlocComponent>}
                        {isAdmin() && <>
                        <ToggableBlocComponent label="Configuration FID" id="provisioning-rule" toggled={togglers["provisioning-rule"]} toggle={toggle}>
                            <FieldComponent
                                type="checksingle"
                                name="provisioning_rule"
                                label="Activer la FID"
                                checksingleLabel="Oui"
                                onChange={value => setValue("provisioning_rule", value)}
                                value={data.provisioning_rule}
                                checked={data.provisioning_rule}
                                disabled={!canPerform("account:write", data)}
                            />
                            {data.provisioning_rule === true && <>
                                <FieldComponent
                                    name="oidc_code"
                                    label="Code pour identifier le compte"
                                    onChange={value => setValue("oidc_code", value)}
                                    value={data.oidc_code}
                                    error={errors.oidc_code}
                                    disabled={!canPerform("account:write", data)}
                                >
                                    A définir conjointement avec l'équipe APAVE en charge de la configuration de la FID
                                </FieldComponent>
                                <div className="row">
                                    <div className="col-4">
                                    <FieldComponent
                                        type="select"
                                        name="fid_profil"
                                        label="Profil des utilisateurs"
                                        options={[
                                            {value: 'ROLE_ADMIN_CUSTOMER', label: 'Administrateur client'},
                                            {value: 'ROLE_USER_CUSTOMER', label: 'Utilisateur simple'}
                                        ]}
                                        onChange={value => {
                                            if (value === "ROLE_ADMIN_CUSTOMER") {
                                                setData({...data, ...{
                                                    "fid_profil": value,
                                                    "fid_access_user_configuration": true,
                                                    "fid_access_tree_management": true,
                                                    "fid_access_profil": true,
                                                    "fid_access_write": true,
                                                    "fid_access_write_hotline": true,
                                                    "fid_watchsites_access": 1,
                                                }});
                                            } else {
                                                setData({...data, ...{
                                                    "fid_profil": value,
                                                    "fid_access_tree_management": false,                                                    
                                                }});
                                            }
                                        }}
                                        value={data.fid_profil}
                                        checked={data.fid_profil}
                                        error={errors.fid_profil}
                                        disabled={!canPerform("account:write", data)}
                                    />
                                    </div>
                                    <div className="col-4">
                                        <FieldComponent
                                            type="checksingle"
                                            name="fid_access_user_configuration"
                                            label="Activation de l'accès aux utilisateurs"
                                            checksingleLabel="Oui"
                                            onChange={value => setValue("fid_access_user_configuration", value)}
                                            value={data.fid_access_user_configuration}
                                            checked={data.fid_access_user_configuration}
                                            error={errors.fid_access_user_configuration}
                                            disabled={!canPerform("account:write", data) || data.fid_profil === "ROLE_ADMIN_CUSTOMER"}
                                        />
                                    </div>
                                    <div className="col-4">
                                        <FieldComponent
                                            type="checksingle"
                                            name="fid_access_tree_management"
                                            label="Activation de l'accès à l'arborescence"
                                            checksingleLabel="Oui"
                                            onChange={value => setValue("fid_access_tree_management", value)}
                                            value={data.fid_access_tree_management}
                                            checked={data.fid_access_tree_management}
                                            error={errors.fid_access_tree_management}
                                            disabled={!canPerform("account:write", data) || data.fid_profil === "ROLE_USER_CUSTOMER"}
                                        />
                                    </div>
                                    <div className="col-4">
                                        <FieldComponent
                                            type="checksingle"
                                            name="fid_access_profil"
                                            label="Activation de l'accès aux profils"
                                            checksingleLabel="Oui"
                                            onChange={value => setValue("fid_access_profil", value)}
                                            value={data.fid_access_profil}
                                            checked={data.fid_access_profil}
                                            error={errors.fid_access_profil}
                                            disabled={!canPerform("account:write", data) || data.fid_profil === "ROLE_ADMIN_CUSTOMER"}
                                        />
                                    </div>
                                    <div className="col-4">
                                        <FieldComponent
                                            type="checksingle"
                                            name="fid_access_write"
                                            label="Activation de l'accès en écriture"
                                            checksingleLabel="Oui"
                                            onChange={value => {
                                                if (value) {
                                                    setData({...data, ...{
                                                        "fid_access_write": true,
                                                        "fid_watchsites_access": 1,
                                                    }});
                                                } else {
                                                    setValue("fid_access_write", value)
                                                }
                                                
                                            }}
                                            value={data.fid_access_write}
                                            checked={data.fid_access_write}
                                            error={errors.fid_access_write}
                                            disabled={!canPerform("account:write", data) || data.fid_profil === "ROLE_ADMIN_CUSTOMER"}
                                        />
                                    </div>
                                    {data.access_hotline === 1 && <div className="col-4">
                                        <FieldComponent
                                            type="checksingle"
                                            name="fid_access_write_hotline"
                                            label="Activation de l'accès en écriture des hotline"
                                            checksingleLabel="Oui"
                                            onChange={value => setValue("fid_access_write_hotline", value)}
                                            value={data.fid_access_write_hotline}
                                            checked={data.fid_access_write_hotline}
                                            error={errors.fid_access_write_hotline}
                                            disabled={!canPerform("account:write", data) || data.fid_profil === "ROLE_ADMIN_CUSTOMER"}
                                        />
                                    </div>}
                                    {data.access_documentary_space === 1 && data.access_newsletter === 1 && <div className="col-4">
                                        <FieldComponent
                                            type="checksingle"
                                            name="fid_alert_documentary_space_activation"
                                            label="Activation de le newsletter"
                                            checksingleLabel="Oui"
                                            onChange={value => setValue("fid_alert_documentary_space_activation", value)}
                                            value={data.fid_alert_documentary_space_activation}
                                            checked={data.fid_alert_documentary_space_activation}
                                            error={errors.fid_alert_documentary_space_activation}
                                            disabled={!canPerform("account:write", data)}
                                        />
                                    </div>}
                                    <div className="col-4">
                                        <FieldComponent
                                            type="checksingle"
                                            name="fid_alert_activation"
                                            label="Activation des alertes"
                                            checksingleLabel="Oui"
                                            onChange={value => setValue("fid_alert_activation", value)}
                                            value={data.fid_alert_activation}
                                            checked={data.fid_alert_activation}
                                            error={errors.fid_alert_activation}
                                            disabled={!canPerform("account:write", data)}
                                        />
                                    </div>
                                    <div className="col-4">
                                        <FieldComponent
                                            type="select"
                                            name="fid_alert_frequency"
                                            label="Fréquence des alertes"
                                            options={[
                                                {value:0, label: "Quotidienne"},
                                                {value:1, label: "Quotidienne + Récapitulatif mensuel"},
                                                {value:2, label: "Hebdomadaire"},
                                                {value:3, label: "Hebdomadaire + Récapitulatif mensuel"},
                                                {value:4, label: "Bimensuelle"},
                                                {value:5, label: "Mensuelle"},
                                            ]}
                                            onChange={value => setValue("fid_alert_frequency", value)}
                                            value={data.fid_alert_frequency}
                                            checked={data.fid_alert_frequency}
                                            error={errors.fid_alert_frequency}
                                            disabled={!canPerform("account:write", data)}
                                        />
                                    </div>
                                    <div className="col-4">
                                        <FieldComponent
                                            type="checksingle"
                                            name="fid_alert_review_activation"
                                            label="Activation des alertes sur les dates de revues"
                                            checksingleLabel="Oui"
                                            onChange={value => setValue("fid_alert_review_activation", value)}
                                            value={data.fid_alert_review_activation}
                                            checked={data.fid_alert_review_activation}
                                            error={errors.fid_alert_review_activation}
                                            disabled={!canPerform("account:write", data)}
                                        />
                                    </div>
                                    <div className="col-4">
                                        <FieldComponent
                                            type="checksingle"
                                            name="fid_alert_deadline_activation"
                                            label="Activation des alertes sur les actions"
                                            checksingleLabel="Oui"
                                            onChange={value => setValue("fid_alert_deadline_activation", value)}
                                            value={data.fid_alert_deadline_activation}
                                            checked={data.fid_alert_deadline_activation}
                                            error={errors.fid_alert_deadline_activation}
                                            disabled={!canPerform("account:write", data)}
                                        />
                                    </div>
                                    <div className="col-4">
                                        <FieldComponent
                                            type="select"
                                            name="fid_watchsites_access"
                                            label="Accès aux points de veille"
                                            options={[{value: 0, label: 'En lecture'}, {value: 1, label: 'En écriture'}]}
                                            onChange={value => setValue("fid_watchsites_access", parseInt(value))}
                                            value={data.fid_watchsites_access}
                                            checked={data.fid_watchsites_access}
                                            error={errors.fid_watchsites_access}
                                            disabled={!canPerform("account:write", data) || data.fid_profil === "ROLE_ADMIN_CUSTOMER" || data.fid_access_write}
                                        />
                                    </div>
                                </div>
                            </>}
                        </ToggableBlocComponent>
                        <ToggableBlocComponent label="Autres paramètres" id="other-parameters" toggled={togglers["other-parameters"]} toggle={toggle}>
                            <FieldComponent
                                type="checksingle"
                                name="anonymization_hotline"
                                label="Activer l'anonymisation des hotlines"
                                checksingleLabel="Oui"
                                onChange={value => setValue("anonymization_hotline", value)}
                                value={data.anonymization_hotline}
                                checked={data.anonymization_hotline}
                                disabled={!canPerform("account:write", data)}
                            />
                            {data.anonymization_hotline && <FieldComponent
                                type="select"
                                name="anonymization_hotline_duration"
                                label="Activer l'anonymisation des hotlines"
                                onChange={value => setValue("anonymization_hotline_duration", value)}
                                value={data.anonymization_hotline_duration}
                                checked={data.anonymization_hotline_duration}
                                disabled={!canPerform("account:write", data)}
                                options={[
                                    {value:1, label: "1 mois"},
                                    {value:6, label: "6 mois"},
                                    {value:12, label: "12 mois"},
                                ]}
                            />}
                            <FieldComponent
                                type="checksingle"
                                name="access_obligation_generator"
                                label="Accès au module de génération des obligations réglementaires"
                                checksingleLabel="Oui"
                                onChange={value => setValue("access_obligation_generator", value)}
                                value={data.access_obligation_generator}
                                checked={data.access_obligation_generator}
                                disabled={!canPerform("account:write", data)}
                            />
                        </ToggableBlocComponent>
                        </>}  
                        {data.id && <ToggableBlocComponent label="Configuration de l'arborescence" id="nodes" toggled={togglers["nodes"]} toggle={toggle} wrapChildren={false}>
                            <AccountTree
                                account={data}
                                consultants={account_consultants}
                                promptChecker={formHasModifications}
                            />                            
                        </ToggableBlocComponent>}
                        <section className="row">
                            <div className="col">
                                <Link id="back-account-list" to="/accounts" className="btn btn-bleu-4">Retour à la liste</Link>
                            </div>
                            <Can
                                perform="account:write"
                                data={data}
                                yes={() => (
                                    <div className="col text-right">
                                        <LoadButton 
                                            loading={saving === "save-and-close"} 
                                            label="Enregistrer et fermer"
                                            name="save-and-close"
                                            id="save-and-close-account"
                                        />
                                        <LoadButton 
                                            loading={saving === "save"} 
                                            label="Enregistrer" 
                                            name="save" 
                                            id="save-account"
                                        />
                                    </div>
                                )}
                            />
                        </section>
                    </form>
                </section>
            </section>
        </DefaultLayout>
    );
}