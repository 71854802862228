import _ from 'lodash';
import AccountApi from 'shared/api/Account';
import { compileDataToSelectOptions } from 'shared/services/Utils';

function AccountAutocomplete(criterias, callback) {

    if (criterias.keyword === "" || criterias.keyword.length < 3) {
        callback([]);
    }

    let queryArgs = {
        "bool": {
            "must": [
                {"wildcard": 
                    {"name": criterias.keyword + "*"}
                }
            ]
        }
    };

    if(criterias.consultant) {
        queryArgs["bool"]["must"].push(
            {"match": {"pilot_consultants": criterias.consultant}}
        );
    }

    AccountApi
        .search(queryArgs, 0, 25, 'name', 'asc')
        .then(([accounts]) => {
            callback(
                compileDataToSelectOptions(accounts, 'id', 'name')
            );
        });
}

export default _.debounce(AccountAutocomplete, 300);