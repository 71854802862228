import _ from 'lodash';
import SheetApi from 'shared/api/Sheet';
import { compileDataToSelectOptions } from 'shared/services/Utils';

function SheetAutocomplete(criterias, callback, orderingField = "title", orderingDirection = 'ASC', minLength = 3, activeTitleSearch = true) {

    if (_.isNil(criterias.keyword) || criterias.keyword === "" || criterias.keyword.length < minLength) {
        callback([]);
    }

    let queryArgs = {
        "bool": {
            "must": []
        }
    };

    if (criterias.keyword) {
        if (activeTitleSearch) {
            queryArgs["bool"]["must"].push({
                "bool": {
                    "should": [
                        {"match": {"id": parseInt(criterias.keyword) ? parseInt(criterias.keyword) : 0}},
                        {"wildcard": {"title": criterias.keyword + "*"}}
                    ]
                }
            });
        } else {
            queryArgs["bool"]["must"].push({
                "bool": {
                    "should": [
                        {"match": {"id": parseInt(criterias.keyword) ? parseInt(criterias.keyword) : 0}}
                    ]
                }
            });
        }
    }

    if (criterias.status) {
        queryArgs["bool"]["must"].push({
            "bool": {
                "should": [
                    {"terms": {"status": criterias.status}}
                ]
            }
        });
    }

    if (criterias.state_synthesis) {
        queryArgs["bool"]["must"].push({
            "bool": {
                "should": [
                    {"terms": {"state_synthesis": criterias.state_synthesis}}
                ]
            }
        });
    }

    if (criterias.add_to_dispatch) {
        queryArgs["bool"]["must"].push({
            "bool": {
                "should": [
                    {"match": {"add_to_dispatch": criterias.add_to_dispatch}}
                ]
            }
        });
    }

    if (criterias.dispatch !== undefined) {
        queryArgs["bool"]["must"].push({
            "bool": {
                "should": [
                    {"match": {"dispatch": criterias.dispatch}}
                ]
            }
        });
    }

    if(criterias.excludeIds && criterias.excludeIds.length > 0) {
        queryArgs["bool"]["must"].push({
            "bool": {
                "must_not": [
                    {"terms": {"id": criterias.excludeIds}}
                ]
            }
        });
    }

    SheetApi
        .search(queryArgs, 0, 25, orderingField, orderingDirection)
        .then(([sheets]) => {
            callback(
                compileDataToSelectOptions(sheets, 'id', ['id', 'title'], ' - ')
            );
        });
}

export default _.debounce(SheetAutocomplete, 300);

export function getSheetDefaultOptions(values, orderingField = "title", orderingDirection = 'ASC') {
    return SheetApi
        .search(
            {"bool": {"must": [{"terms": {"id": values}}]}},
            0,
            25,
            orderingField,
            orderingDirection
        )
        .then(([sheets]) => compileDataToSelectOptions(sheets, 'id', ['id', 'title'], ' - '));
}
