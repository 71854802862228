import _ from "lodash";
import { useHistory, useLocation } from "react-router-dom";

export function useAutomaticRedirect() {
    const history = useHistory();
    const pathname = useLocation().pathname;

    function getLastVisitPath() {
        if (!_.isNil(localStorage.getItem("last_visit"))) {
            const lastVisit = JSON.parse(localStorage.getItem("last_visit"));
            return lastVisit.pathname;
        } else {
            return "/";
        }
    }

    function updateLastVisit() {
        if (pathname !== "/login"
            && pathname !== "/loader"
            && pathname !== "/selectaccount"
            && pathname !== "/logout"
            && pathname !== "/obligations"
        ) {
            localStorage.setItem(
                "last_visit",
                JSON.stringify({
                    pathname: pathname,
                    date: new Date()
                })
            );
        }
    }

    function removeLastVisit() {
        localStorage.removeItem("last_visit");
    }

    function shouldRedirect() {
        return !(pathname !== "/"
            || history.length <= 1
            || _.isUndefined(localStorage.getItem("last_visit"))
            || document.location.pathname === getLastVisitPath()
        );    
    }

    return [updateLastVisit, getLastVisitPath, shouldRedirect, removeLastVisit];
}