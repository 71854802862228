import { confirmAlert } from 'react-confirm-alert';

export default function confirm(message, callbackConfirm, callbackCancel = null) {

    confirmAlert({
        customUI: ({ onClose }) => {
            return (<section className="bloc">
                  <header className="bg-vert-apave">Confirmation</header>
                  <div className="bg-gris-10 border-gris-25 border-lrb">
                      <div dangerouslySetInnerHTML={{__html: message}} />
                      <button onClick={e => { onClose(); callbackConfirm(); }} className="btn btn-primary">Oui</button>
                      <button onClick={e => { onClose(); if (callbackCancel) { callbackCancel(); } }} className="btn btn-na">Non</button>
                    </div>
                </section>
            );
        }
    });
}