 import React from 'react';

export default function ToggableBlocComponent(props) {
    
    const { id, children, label, toggled, toggle, className, margin, wrapChildren } = props;

    function preToggle(event) {
        event.preventDefault();
        event.stopPropagation();
        toggle(id);
    }

    return (
        <section className="bloc" style={{marginBottom: (margin ?? 10)}} id={id || null}>
            <header className={props.headerClassName}>
                <h2 className="uppercase"><button id={id ? `${id}-button` : null} onClick={(event) => preToggle(event)} className={toggled ? "toggler actif" : "toggler"} type="button">{label || ""}</button></h2>
            </header>
            {wrapChildren && <section className={toggled ? className : `${className} d-none`}>{children}</section>}
            {!wrapChildren && !toggled && <section className="d-none">{children}</section>}
            {!wrapChildren && toggled && <>{children}</>}
        </section>
    );
}

ToggableBlocComponent.defaultProps = {
    className: "toggle-group border-rlb border-gris-40",
    wrapChildren: true
};