import _ from "lodash";

export const HOTLINE_STATUS_DRAFT = 0;
export const HOTLINE_STATUS_OPEN = 1;
export const HOTLINE_STATUS_ANSWERED = 2;

export const HOTLINE_STATUS = {
    [HOTLINE_STATUS_DRAFT]: "Brouillon",
    [HOTLINE_STATUS_OPEN]: "En attente",
    [HOTLINE_STATUS_ANSWERED]: "Répondu",
}

export const HOTLINE_STATUS_COLOR = {
    [HOTLINE_STATUS_DRAFT]: "na",
    [HOTLINE_STATUS_OPEN]: "alert",
    [HOTLINE_STATUS_ANSWERED]: "valid",
}

export function getHotlineStatusLabel(status) {
    return HOTLINE_STATUS[status];
}

export function getHotlineStatusColor(status) {
    return HOTLINE_STATUS_COLOR[status];
}

export function getHotlineStatus(includeDraft = true) {
    let status = [];

    for (const value of _.keys(HOTLINE_STATUS)) {
        if (!includeDraft && parseInt(value) === HOTLINE_STATUS_DRAFT) continue;
        status.push({
            value: parseInt(value), 
            label: HOTLINE_STATUS[value],
            color: HOTLINE_STATUS_COLOR[value],
        });
    }

    return status;
};
