import { useState, toast, _ } from 'commons';
import { convertCriteriesToSearchQuery } from 'shared/services';

export function useList(formId, api, preFetchData, defaultSorting, initialCriterias = {}, defaultDirection = "asc", defaultLimit = 20, modifyQuery = null, contextCriterias = {}, modifyRows = null) {
    let defaultPage = 1;

    defaultSorting = localStorage.getItem(formId + "-sorting") ? localStorage.getItem(formId + "-sorting") : defaultSorting;
    defaultDirection = localStorage.getItem(formId + "-direction") ? localStorage.getItem(formId + "-direction") : defaultDirection;
    defaultLimit = localStorage.getItem(formId + "-limit") ? localStorage.getItem(formId + "-limit") : defaultLimit;
    defaultPage = localStorage.getItem(formId + "-page") ? parseInt(localStorage.getItem(formId + "-page")) : defaultPage;
    
    const [loading, setLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [totalrows, setTotalrows] = useState(0);
    const [sorting, setSorting] = useState(defaultSorting);
    const [filters, setFilters] = useState({});
    const [direction, setDirection] = useState(defaultDirection);
    const [limit, setLimit] = useState(defaultLimit);
    const [page, setPage] = useState(defaultPage);
    const [criterias, setCriterias] = useState(getDefaultCriterias());
    const [activeCriterias, setActiveCriterias] = useState({});

    function getDefaultCriterias(updateInitialCriterias = false, newInitialCriterias = {}) {
        let defaultCriterias = {};
        if (localStorage.getItem(formId + "-criterias")) {
            defaultCriterias = JSON.parse(localStorage.getItem(formId + "-criterias"));
        } 
        if (updateInitialCriterias) {
            initialCriterias = newInitialCriterias;
        }
        defaultCriterias = {...initialCriterias, ...defaultCriterias, ...contextCriterias};
        return defaultCriterias;
    }

    function fetchData(newSorting, newDirection, newCriterias, newLimit, newPage, newFilters) {
        let additionnalParams = null;
        if (preFetchData) {
            [newSorting, newDirection, newCriterias, newLimit, newPage, newFilters, additionnalParams] = preFetchData(newSorting, newDirection, newCriterias, newLimit, newPage, newFilters);
        }
        let query = convertCriteriesToSearchQuery(newCriterias, newFilters);
        if (modifyQuery) {
            query = modifyQuery(query);
        }
        const from = (newPage - 1) * newLimit;
        let args = [query, from, newLimit, newSorting, newDirection];
        if (additionnalParams) {
            args = args.concat(additionnalParams);
        }
        return api.search(...args);
    }

    function preSetRows(rows) {
        if (modifyRows) {
            rows = modifyRows(rows);
        }
        setRows(rows);
    }

    function updateSorting(newsorting, newdirection) {
        setLoading(true);
        localStorage.setItem(formId + "-sorting", newsorting);
        localStorage.setItem(formId + "-direction", newdirection);
        localStorage.setItem(formId + "-page", 1);

        fetchData(
            newsorting,
            newdirection,
            criterias,
            limit,
            1,
            filters
        ).then(([freshRows, count, total]) => {
            preSetRows(freshRows);
            setTotalrows(total);
            setSorting(newsorting);
            setDirection(newdirection);
            setPage(1);
            setLoading(false);
        })
        .catch(error => console.log(error));
    }

    function addCriteria(criteria, value) {
        setCriterias(Object.assign({}, criterias, {[criteria]: value}));
    }

    function updateLimit(newLimit) {
        setLoading(true);
        localStorage.setItem(formId + "-limit", newLimit);
        fetchData(
            sorting,
            direction,
            criterias,
            newLimit,
            1,
            filters
        ).then(([freshRows, count, total]) => {
            preSetRows(freshRows);
            setTotalrows(total);
            setLimit(newLimit);
            setPage(1);
            setLoading(false);
        })
        .catch(error => console.log(error));
    }

    function updatePage(newPage) {
        setLoading(true);
        localStorage.setItem(formId + "-page", newPage);
        fetchData(
            sorting,
            direction,
            criterias,
            limit,
            newPage,
            filters
        ).then(([freshRows, count, total]) => {
            preSetRows(freshRows);
            setTotalrows(total);
            setPage(newPage);
            setLoading(false);
        })
        .catch(error => console.log(error));
    }

    function submitSearch(newFilters, callback, reinitPage = true) {
        setFilters(newFilters);
        setLoading(true);
        if (reinitPage) {
            localStorage.setItem(formId + "-page", 1);
        }
        localStorage.setItem(formId + "-criterias", JSON.stringify(criterias));
        fetchData(
            sorting,
            direction,
            criterias,
            limit,
            (reinitPage ? 1 : page),
            newFilters
        ).then(([freshRows, count, total]) => {
            preSetRows(freshRows);
            setTotalrows(total);
            setCriterias(criterias);
            setActiveCriterias(criterias);
            if (reinitPage) {
                setPage(1);
            }
            setLoading(false);
            if (callback) {
                _.delay(callback, 500);
            }
        })
        .catch(error => toast.error(error.response.data.message));
    }

    function refresh(clearFilter = false, updateInitialCriterias = false, newInitialCriterias = {}, reinitPage = true) {
        let newcriterias = criterias;
        if (clearFilter) {
            localStorage.setItem(formId + "-criterias", JSON.stringify({}));
            newcriterias = getDefaultCriterias(updateInitialCriterias, newInitialCriterias);
            setCriterias(newcriterias);
        }
        setLoading(true);
        
        let newpage = reinitPage ? 1 : page;
        localStorage.setItem(formId + "-page", newpage);
        localStorage.setItem(formId + "-criterias", JSON.stringify(newcriterias));
        fetchData(
            sorting,
            direction,
            newcriterias,
            limit,
            newpage,
            filters
        ).then(([freshRows, count, total]) => {
            preSetRows(freshRows);
            setTotalrows(total);
            setLoading(false);
            setActiveCriterias(newcriterias);
            setPage(newpage);
        })
        .catch(error => console.log(error));
    }

    function loadCriterias(newCriterias, newFilters = null) {
        setLoading(true);
        let localFilters = {...newFilters};
        if (localFilters === null) {
            localFilters = {...filters};
        }
        setFilters(localFilters);
        setCriterias(newCriterias);
        localStorage.setItem(formId + "-criterias", JSON.stringify(newCriterias));
        fetchData(
            sorting,
            direction,
            newCriterias,
            limit,
            page,
            localFilters
        ).then(([freshRows, count, total]) => {
            preSetRows(freshRows);
            setTotalrows(total);
            setLoading(false);
            setActiveCriterias(newCriterias);
        })
        .catch(error => console.log(error));        
    }

    return [
        rows,
        totalrows,
        criterias,
        sorting,
        direction,
        limit,
        page,
        preSetRows,
        setTotalrows,
        addCriteria,
        updateSorting,
        updateLimit,
        updatePage,
        submitSearch,
        loading,
        setLoading,
        refresh,
        activeCriterias,
        loadCriterias
    ];
}